import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@shared';
import { IPaginationParams } from '../interfaces/IPaginationParamas';

@Injectable({ providedIn: 'root' })
export class SettingService {
  constructor(private http: HttpClient) {}

  getAllSettingItems(slug: any, filterFields: any) {
    return this.http.post<ApiResponse>(`/v1/admin/settings/get/${slug}`, filterFields);
  }
  getDropDownList(params: IPaginationParams) {
    return this.http.post<ApiResponse>(`/v1/home/${params.dropDownFor}`, params);
  }
  deleteSettingItems(slug: string, id: string) {
    return this.http.delete<ApiResponse>(`/v1/admin/settings/${slug}/${id}`);
  }
  createSettingItems(slug: string, payload: any) {
    return this.http.post<ApiResponse>(`/v1/admin/settings/${slug}`, payload);
  }
  editSettingItems(slug: string, id: string, payload: any) {
    return this.http.put<ApiResponse>(`/v1/admin/settings/${slug}/${id}`, payload);
  }
  getSettingItemById(slug: string, id: string) {
    return this.http.get<ApiResponse>(`/v1/admin/settings/${slug}/${id}`);
  }
}
