import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cover404',
  templateUrl: './cover404.component.html',
  styleUrls: ['./cover404.component.scss'],
})

/**
 * Cover 400 component
 */
export class Cover404Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
