<div class="authentication-bg min-vh-100">
  <div class="bg-overlay bg-white"></div>

  <div class="container">
    <div class="d-flex flex-column min-vh-100 px-2 position">
      <div class="card auth-cover-card overflow-hidden cardPosition customCard col-lg-6">
        <div class="row g-0 card-height">
          <!-- <div class="col-lg-6 d-lg-block d-none">
          <div class="auth-img"></div>
        </div> -->
          <div class="col-lg-12 col-12">
            <div class="px-4 px-lg-5 py-3 h-100 d-flex align-items-center justify-content-center">
              <div class="w-100">
                <div class="mb-4 text-center">
                  <a routerLink="/" class="d-block auth-logo">
                    <!-- <img [src]="logoUrl" alt="" class="w-100 w-sm-50" /> -->
                  </a>
                </div>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
