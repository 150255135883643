import { Component, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { LayoutSettingsService } from 'app/core/services/layout-settings.service';
import { permissionModel } from '@shared/store/permission/permission.model';
import { Store } from '@ngrx/store';
import { loadPermissions } from '@shared/store/permission/permission.actions';
import { getPermission } from '@shared/store/permission/permission.selector';
import { NgxPermissionsService, NgxPermissionsConfigurationService } from 'ngx-permissions';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  layoutType!: string;
  permissionList!: permissionModel[];
  constructor(
    private _layout: LayoutSettingsService,
    private permissionsService: NgxPermissionsService,
    private store: Store<{ permission: permissionModel[] }>,
  ) {}

  ngOnInit(): void {
    this._layout.setDefaultOptions();
    this.store.dispatch(loadPermissions());
    this.store.select(getPermission).subscribe((item) => {
      this.permissionList = item;
      const newData: any = [];
      this.permissionList.forEach((item) => {
        if (item.moduleName) {
          item.read && newData.push(item.moduleName?.split(' ').join('') + ':read');
          item.write && newData.push(item.moduleName?.split(' ').join('') + ':write');
          item.update && newData.push(item.moduleName?.split(' ').join('') + ':update');
          item.delete && newData.push(item.moduleName?.split(' ').join('') + ':delete');
        }
      });
      this.permissionsService.loadPermissions(newData);
    });
  }
}
