<div class="row">
  <div class="col-sm-12 col-xl-10 col-md-12 my-2">
    <div class="perPageDatatable">
      <ng-container *ngIf="(paginationPosition === 'top' || paginationPosition === 'both') && isPaginationEnable">
        <ng-container *ngTemplateOutlet="pagination"></ng-container>
      </ng-container>
      <ng-container *ngIf="resetFilters"
        ><button (click)="onResetFilters()" type="button" class="btn btn-warning">
          <i class="uil uil-redo"></i>
          <span class="hidebuttonName">{{ 'DATATABLE.BUTTONS.RESET' | translate }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="isPrintEnable">
        <button (click)="onBtnPrint()" type="button" class="btn btn-soft-primary">
          <i class="uil uil-print"></i>
          <span class="hidebuttonName"> Print</span>
        </button>
      </ng-container>
      <ng-container *ngIf="isExportable">
        <button (click)="onBtnExport()" type="button" class="btn btn-soft-purple">
          <i class="uil uil-export"></i>
          <span class="hidebuttonName"> {{ 'DATATABLE.BUTTONS.EXPORT' | translate }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="isDeletable">
        <button
          (click)="getSelectedRowsData()"
          [disabled]="numberOfSelectedRows === 0"
          type="button"
          class="btn btn-danger"
        >
          <i class="uil uil-trash"></i>
          <span class="hidebuttonName"> {{ 'BUTTONS.DELETESELECTEDPRODUCTS.TEXT' | translate }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="isDeleteAll">
        <button (click)="deleteAllData()" type="button" class="btn btn-danger">
          <i class="uil uil-trash"></i>
          <span class="hidebuttonName"> {{ 'BUTTONS.DELETEALLPRODUCTS.TEXT' | translate }}</span>
        </button>
      </ng-container>
      <ng-content class="xlx" select="[topButtons]"></ng-content>
      <div class="input-group my-auto dateRangeSelector" *ngIf="isDateRangeSelectionOn">
        <div class="input-group-text bg-primary text-white border-r-none">
          <i class="uil uil-calendar-alt"></i>
        </div>
        <input
          class="form-control flatpickr-input mw-25"
          type="text"
          (input)="onDateRangeSelect()"
          [(ngModel)]="dateRange"
          mwlFlatpickr
          mode="range"
          [convertModelValue]="true"
        />
      </div>
      <ng-container *ngIf="isAddProduct">
        <button
          (click)="getSelectedRowsData()"
          [disabled]="numberOfSelectedRows === 0"
          type="button"
          class="btn btn-info"
        >
          <span class="hidebuttonName">
            {{
              isCoupon
                ? ('SALES.PRODUCT_IN_SALES.PRODUCTLIST.ADD_SELECTED_CATEGORY.TEXT' | translate)
                : ('SALES.PRODUCT_IN_SALES.PRODUCTLIST.ADD_SELECTED_PRODUCT.TEXT' | translate)
            }}
          </span>
        </button>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="isSearchable">
    <div class="col-sm-12 col-xl-2 col-md-12 d-flex my-2">
      <div class="input-group my-auto">
        <div class="input-group-text bg-primary text-white border-r-none">
          <i class="uil uil-search"></i>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'DATATABLE.BUTTONS.SEARCH' | translate }}"
          [(ngModel)]="queryParams"
          (ngModelChange)="queryParamsSubject.next(queryParams)"
        />
      </div>
    </div>
  </ng-container>
  <app-common-loading [count]="10" [appearance]="'line'" [isLoading]="isLoading">
    <div class="col-12 mt-2" *ngIf="showGrid" #grid>
      <ag-grid-angular
        [gridOptions]="gridOptions"
        id="commonGrid"
        domLayout="autoHeight"
        class="ag-theme-material"
        [rowData]="rowData"
        [pagination]="isPaginationEnable"
        [suppressPaginationPanel]="true"
        [paginationPageSize]="perPage"
        [defaultColDef]="defaultColDef"
        [animateRows]="true"
        [rowDragManaged]="true"
        (rowDragEnd)="onRowDragEnd($event)"
        [suppressRowClickSelection]="true"
        [rowGroupPanelShow]="rowGroupPanelShow"
        [columnDefs]="columnDefs"
        (sortChanged)="onSortChange($event)"
        (dragStopped)="onColumnOrderChange()"
        (filterChanged)="onfilterChange($event)"
        (gridReady)="onGridReady($event)"
        [suppressExcelExport]="true"
        [rowSelection]="rowSelection"
        [enableRtl]="enableRtl"
        [loadingOverlayComponent]="loadingOverlayComponent"
        [loadingOverlayComponentParams]="{ gridCount: perPage > 3 ? perPage : 3 }"
        [suppressDragLeaveHidesColumns]="true"
        [overlayNoRowsTemplate]="'No Record Found'"
        (selectionChanged)="onSelectionChange()"
        [isRowSelectable]="isRowSelectable"
        (firstDataRendered)="onFirstDataRendered($event)"
      >
      </ag-grid-angular>
      <!--  //customLoading -->
      <!-- [loadingOverlayComponent]="loadingOverlayComponent" -->
    </div>
  </app-common-loading>
  <div class="col-12" *ngIf="(paginationPosition === 'bottom' || paginationPosition === 'both') && isPaginationEnable">
    <ng-container *ngTemplateOutlet="pagination"></ng-container>
  </div>
</div>
<ng-template #pagination>
  <div class="card">
    <div class="card-body">
      <div class="my-auto pagination-rounded perPageDatatable">
        <ngb-pagination
          [collectionSize]="collectionSize"
          [(page)]="currentPage"
          [maxSize]="6"
          [pageSize]="perPage"
          [rotate]="true"
          [ellipses]="true"
          [boundaryLinks]="true"
          (pageChange)="onPageClick($event)"
        ></ngb-pagination>
        <h6 class="my-auto">{{ 'DATATABLE.BUTTONS.PERPAGE' | translate }}</h6>
        <ng-select
          style="min-width: 100px"
          [items]="pageSizeOptions"
          [(ngModel)]="perPage"
          (change)="onPageSizeChanged()"
          [searchable]="false"
          [clearable]="false"
          [appendTo]="'body'"
          bindLabel="pageSize"
          bindValue="value"
        >
        </ng-select>
        <div class="ms-auto">
          <h6 class="my-auto text-muted">
            <span class="mx-1">
              {{ currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1 }}
            </span>
            {{ 'DATATABLE.BUTTONS.TO' | translate }}
            <span class="mx-1">
              {{
                currentPage === 1
                  ? rowData?.length < perPage
                    ? rowData?.length
                    : perPage
                  : (currentPage - 1) * perPage + rowData?.length
              }}
            </span>
            {{ 'DATATABLE.BUTTONS.OF' | translate }}
            {{ collectionSize }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</ng-template>
