import { Component, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { EventService } from './event.service';
import { Subject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'es', 'de', 'it', 'ru', 'he'];
  public pinned = new Subject<boolean | 'left' | 'right' | null>();
  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private eventService: EventService,
  ) {
    this.translate.addLangs(this.languages);
    // if (this.cookieService.check(environment.languageCookieKey)) {
    //   browserLang = this.cookieService.get(environment.languageCookieKey);
    // } else {
    //   browserLang = translate.getBrowserLang();
    // }
    const browserLang = this.cookieService.get(environment.languageCookieKey) || 'en';

    translate.use(browserLang?.match(/en|es|de|it|ru|he/) ? browserLang : 'en');
    if (this.translate.currentLang === 'he') {
      this.changeDirection('rtl');
    } else {
      this.changeDirection('ltr');
    }
  }

  /***
   * Cookie Language set
   */
  public setLanguage(lang: any) {
    this.translate.use(lang);
    this.cookieService.set(environment.languageCookieKey, lang, { path: '/', expires: environment.cookieExpireTime });
    if (lang === 'he') {
      this.changeDirection('rtl');
    } else {
      this.changeDirection('ltr');
    }
  }

  changeDirection(mode: string) {
    document.documentElement.setAttribute('dir', mode);
    this.eventService.broadcast('changeDirection', mode);
  }
  public getCurrentLang() {
    return this.cookieService.get(environment.languageCookieKey).trim() || 'en';
  }
}
