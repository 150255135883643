import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationParams } from '@interfaces';
import { ApiResponse } from '@shared';
import { permissionModel } from '@shared/store/permission/permission.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  constructor(private http: HttpClient) {}

  getAllModules(filterData: IPaginationParams) {
    let params = new HttpParams()
      .set('pageNum', filterData.pageNum.toString())
      .set('pageLimit', filterData.pageLimit.toString())
      .set('search', filterData.search ? filterData.search : '')
      .set('sortField', filterData.sortField ? filterData.sortField : '')
      .set('sortOrder', filterData.sortOrder ? filterData.sortOrder.toString() : '')
      .set('forSidebar', filterData.forSidebar ? filterData.forSidebar.toString() : '');
    filterData?.columnFilters?.forEach((ele) => {
      const [key, value] = Object.entries(ele)[0];
      params = params.set(key, value?.$regex);
    });
    return this.http.get<ApiResponse>('v1/admin/modules/get-all-modules', { params });
  }

  getAllPermission(): Observable<permissionModel[]> {
    return this.http.get<permissionModel[]>('/v1/admin/permission/get-all-permission');
  }
}
