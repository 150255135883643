<div class="row">
  <div class="col">
    <span *ngIf="showEditButton">
      <a
        title="Edit"
        *ngIf="!editWithModal"
        class="btn btn-sm btn-outline-purple mx-1"
        [routerLink]="displayValue.data.editRoutePath + displayValue.data._id"
        (click)="onEditAction(displayValue)"
        [ngClass]="{ disabled: !isUpdate }"
      >
        <i-feather name="edit"></i-feather>
      </a>
      <a
        title="Edit"
        *ngIf="editWithModal"
        class="btn btn-sm btn-outline-purple mx-1"
        (click)="onActionClick('edit', displayValue.data._id, displayValue)"
        [ngClass]="{ disabled: !isUpdate }"
      >
        <i-feather name="edit"></i-feather>
      </a>
    </span>
    <button
      title="Delete"
      *ngIf="showDeleteButton"
      class="btn btn-sm btn-outline-danger mx-1"
      (click)="onActionClick('delete', displayValue.data._id, displayValue)"
      [ngClass]="{ disabled: !isDelete }"
    >
      <i-feather name="trash-2"></i-feather>
    </button>
    <button
      title="View"
      *ngIf="showViewButton"
      class="btn btn-sm btn-outline-primary mx-1"
      (click)="onActionClick('view', displayValue.data._id, displayValue)"
      [ngClass]="{ disabled: !isRead }"
    >
      <i-feather name="eye"></i-feather>
    </button>

    <button
      title="History"
      *ngIf="showHistoryButton"
      class="btn btn-sm btn-outline-info mx-1"
      (click)="onActionClick('history', displayValue.data._id, displayValue)"
      [ngClass]="{ disabled: !isRead }"
    >
      <i-feather name="clock"></i-feather>
    </button>

    <button
      title="Transfer"
      *ngIf="showStockTransfer"
      class="btn btn-sm btn-outline-info mx-1"
      (click)="onActionClick('stockTransfer', displayValue.data._id, displayValue)"
      [ngClass]="{ disabled: !isRead }"
    >
      <i-feather name="repeat"></i-feather>
    </button>
    <button
      title="Download"
      *ngIf="showDownloadButton"
      class="btn btn-sm btn-outline-info mx-1"
      (click)="onActionClick('download', displayValue.data._id, displayValue)"
      [ngClass]="{ disabled: !isRead }"
    >
      <i-feather name="download"></i-feather>
    </button>
  </div>
</div>
