import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'app/core/services/event.service';
import { LayoutSettings, LayoutSettingsService } from 'app/core/services/layout-settings.service';
import { TopbarService } from 'app/core/services/topbar.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss'],
})
export class RightsidebarComponent implements OnInit {
  topbar: string | undefined;
  sidebarcolor: string | undefined;
  direction: string | undefined;
  notificationMessage: any;
  notificationCount: any;

  constructor(
    private eventService: EventService,
    private _layout: LayoutSettingsService,
    public notificationService: TopbarService,
    private _toast: ToastrService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    const settingsFromCookie: LayoutSettings = this._layout.getCurrentLayoutSettings();
    this.topbar = settingsFromCookie.topbar;
    this.sidebarcolor = settingsFromCookie.sidebarcolor;
    this.direction = settingsFromCookie.direction;
    this.getNotificationListing();
  }
  public hide() {
    document.body.classList.remove('right-bar-enabled');
  }

  changeMode(mode: string) {
    this.sidebarcolor = mode;
    this.topbar = mode;
    this.eventService.broadcast('changeMode', mode);
  }

  changeDirection(direction: string) {
    this.direction = direction;
    this.eventService.broadcast('changeDirection', direction);
  }

  changeTopbar(topbar: string) {
    this.topbar = topbar;
    this.eventService.broadcast('changeTopbar', topbar);
  }

  changeSidebarColor(sidebarcolor: string) {
    this.sidebarcolor = sidebarcolor;
    this.eventService.broadcast('changeSidebarColor', sidebarcolor);
  }

  getNotificationListing() {
    this.notificationService.getNotificationListing().subscribe({
      next: (response: any) => {
        this.notificationMessage = response.data;
      },
      error: (error) => {
        this._toast.error(error || this.translate.instant('WENTWRONG.TEXT'), 'Error');
      },
    });
  }

  deleteAllNotification() {
    this.notificationService.deleteAllNotification().subscribe({
      next: (response: any) => {
        this._toast.success(response.message);
        this.getNotificationListing();
      },
      error: (error) => {
        this._toast.error(error || this.translate.instant('WENTWRONG.TEXT'), 'Error');
      },
    });
    this.hide();
  }
}
