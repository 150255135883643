import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '../services/token.service';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LoginGuard {
  constructor(
    private _token: TokenService,
    private _locationService: Location,
  ) {} // private _token: TokenService, // private _router: Router,

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const accessToken = this._token.getTokens().accessToken;
    if (accessToken) {
      this._locationService.back();
      return false;
    } else {
      return true;
    }
  }
}
