import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LOAD_PERMISSION, loadpermissionsuccess } from './permission.actions';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';
import { ModulesService } from '@shared/services';
import { Injectable } from '@angular/core';
@Injectable()
export class permissionEffects {
  constructor(
    private action$: Actions,
    public _moduleService: ModulesService,
  ) {}

  _permission = createEffect(() =>
    this.action$.pipe(
      ofType(LOAD_PERMISSION),
      exhaustMap((action) => {
        return this._moduleService.getAllPermission().pipe(
          map((data) => {
            return loadpermissionsuccess({ permissionList: data });
          }),
          catchError(() => EMPTY),
        );
      }),
    ),
  );
}
