import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { UploadS3Service } from 'app/core/services/uploadFile.service';
import { imageSieCheck } from './imageSizeCheck';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class CommonDropzoneComponent {
  @ViewChild('dz') drpzone!: DropzoneComponent;
  signedURL: string = '';
  binaryFile!: any;
  @Output() successEvent: EventEmitter<{ param1: string; param2: any; param3: any }> = new EventEmitter<{
    param1: string;
    param2: any;
    param3: any;
  }>();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() sending: EventEmitter<any> = new EventEmitter<any>();
  @Input() DrpzoneparamName: string = '';
  @Input() name: string = '';
  @Input() message: string = '';
  public config: DropzoneConfigInterface = {
    clickable: true,
    autoReset: 1,
    errorReset: null,
    createImageThumbnails: false,
    paramName: this.DrpzoneparamName,
    method: 'put',
    url: () => {
      return this.signedURL;
    },
    autoProcessQueue: false,
    accept: async (file, done) => {
      try {
        const ratio = imageSieCheck(this.DrpzoneparamName);
        const filePath = this.uploadS3service.generateDynamicUploadPath(this.DrpzoneparamName, file.name);
        const binaryData = await this.getBinaryData(file);
        const obj = {
          urls: [{ key: this.DrpzoneparamName, value: filePath }],
        };
        if (this.DrpzoneparamName === 'profileImg' && !file.type.startsWith('image/')) {
          this.config.dictRemoveFile = '';
          done(this.translate.instant('BOOKS.MESSAGESE.IMAGEUPLOADERROR'));
          return;
        }
        if (this.DrpzoneparamName === 'audioFile' && !file.type.startsWith('audio/')) {
          this.config.dictRemoveFile = '';
          done(this.translate.instant('BOOKS.MESSAGESE.AUDIOFILEUPLOADERROR'));
          return;
        }
        if (this.DrpzoneparamName === 'ePubFile' && !file.type.startsWith('application/epub+zip')) {
          this.config.dictRemoveFile = '';
          done(this.translate.instant('BOOKS.MESSAGESE.EPUBFILEUPLOADERROR'));
          return;
        }
        if (!(ratio.height === 0 && ratio.width === 0)) {
          const dimensions = await this.getImageDimensions(file);
          if (!this.areDimensionsMatching(ratio, dimensions)) {
            this.config.dictRemoveFile = '';
            done(this.translate.instant('IMAGEDIMENSIONERROR.TEXT'));
            return;
          }
        }
        const response = await this.uploadS3service.uploadFile(obj);
        this.signedURL = response?.message?.urls[0]?.value;

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', this.signedURL, true);

        // Set Content-Type and other headers as needed
        xhr.setRequestHeader('Content-Type', file.type);

        // Handle the progress of the upload
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentage = (event.loaded / event.total) * 100;
            // Manually update the progress here if needed
            this.drpzone.directiveRef?.dropzone().emit('uploadprogress', file, percentage);
            if (percentage === 100) {
              this.drpzone.directiveRef?.dropzone().removeAllFiles();
              this.drpzone.directiveRef?.dropzone().processQueue();
              this.drpzone.directiveRef?.dropzone().emit('success', file, file);
            }
          }
        };
        xhr.onerror = () => {
          done('Failed to upload file'); // Handle upload failure
        };
        // Send the binary data directly
        xhr.send(binaryData);
      } catch (error) {
        done('Error in accept function', error);
      }
    },
    uploadMultiple: false,
  };
  private areDimensionsMatching(
    expectedRatio: { height: number; width: number },
    actualDimensions: { width: number; height: number },
  ): boolean {
    const { width, height } = actualDimensions;
    const isMatching = height === expectedRatio.height && width === expectedRatio.width;
    return isMatching;
  }

  private getImageDimensions(file: File): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.src = event.target.result as string;
        } else {
          reject('Failed to read file as binary data');
        }
      };
      reader.onerror = () => {
        reject('Error reading file');
      };
      reader.readAsDataURL(file);
    });
  }

  constructor(
    public uploadS3service: UploadS3Service,
    private _toast: ToastrService,
    public translate: TranslateService,
  ) {}

  private getBinaryData(file: File): Promise<Uint8Array> {
    return new Promise<Uint8Array>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result instanceof ArrayBuffer) {
          const binaryData: Uint8Array = new Uint8Array(event.target.result);
          resolve(binaryData);
        } else {
          reject('Failed to read file as binary data');
        }
      };
      reader.onerror = () => {
        reject('Error reading file');
      };
      reader.readAsArrayBuffer(file);
    });
  }

  getBinaryFromFile(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', (err) => reject(err));

      reader.readAsBinaryString(file);
    });
  }
  processing(file: any) {
    this.config.url = file.uploadURL;
  }
  handleUploadSuccess(Data: any, value?: any) {
    const [file] = Data;
    const filePath = this.uploadS3service.generateDynamicUploadPath(this.DrpzoneparamName, file.name);
    this.successEvent.emit({ param1: filePath, param2: Data, param3: value });
  }
  handleonErrorUpload(Data: any) {
    this.errorEvent.emit(Data);
  }
}
