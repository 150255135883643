import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  permissionKey: string = environment.permissionKey;
  constructor(
    private http: HttpClient,
    private _ngxPermissionService: NgxPermissionsService,
  ) {}

  getAllPermissions(): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('v1/permission-templates/getAllPermissionTemplates', {});
  }

  setPermissions(permissions: any[]) {
    localStorage.setItem(this.permissionKey, JSON.stringify(permissions));
    this.loadPermissions();
  }
  addPermissions() {
    const permissionData = JSON.parse(localStorage.getItem(this.permissionKey) ?? '[]');
    const allPermisisonsData: string[] = [];
    permissionData?.forEach((module: { moduleId: string; moduleName: string; read: boolean; write: boolean }) => {
      if (module.write && module.read) {
        allPermisisonsData.push(module.moduleName + '_read_write');
      } else if (module.read && !module.write) {
        allPermisisonsData.push(module.moduleName + '_read');
      }
    });
    return allPermisisonsData;
  }

  loadPermissions() {
    this._ngxPermissionService.loadPermissions(this.addPermissions());
  }
}
