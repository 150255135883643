import { Component, EventEmitter, Output } from '@angular/core';
import { IPaginationParams } from '@interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { statusEnum } from '@shared/enums/status';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ProductVerificationService } from 'app/core/services/ProductVerification.service';
import { ProductReportComponent } from 'app/pages/purchase-product/product-report/product-report.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quotation-status-cell-renderer',
  templateUrl: './quotation-status-cell-renderer.component.html',
  styleUrl: './quotation-status-cell-renderer.component.scss',
})
export class QuotationStatusCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public isConfirmed: boolean = false;
  public isReported: boolean = false;
  public Approved: boolean = false;
  public Reject: boolean = false;
  public additionalValue: string = '';
  public isBack = false;
  purchaseOrderId!: string | null;
  permissionsSubscription: Subscription | undefined;
  isWrite = false;
  @Output() approveClicked: EventEmitter<string> = new EventEmitter<string>(); // Create EventEmitter
  filterParams: IPaginationParams = {
    pageLimit: 10,
    pageNum: 1,
    sortField: '',
    sortOrder: '',
    search: '',
    columnFilters: [],
  };
  constructor(
    private modalService: NgbModal,
    private permissionsService: NgxPermissionsService,
    private productVerificationService: ProductVerificationService,
  ) {}

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    this.permissionsSubscription = this.permissionsService.permissions$.subscribe((permissions) => {
      if (this.params.moduleName) {
        this.isWrite = permissions && permissions[`${this.params.moduleName}:write`] ? true : false;
      }
    });
    this.additionalValue = this.params.additionalValue;
    this.isBack = this.params.isBack;
    if (this.additionalValue == 'PO') {
      this.isConfirmed = params.data.isConfirmed ?? false;
      this.isReported = params.data.isReported ?? false;
    } else if (this.additionalValue == 'departmentHead') {
      this.Approved = params.data.currentStatus == statusEnum.approve ?? false;
      this.Reject = params.data.currentStatus == statusEnum.reject ?? false;
    }
  }

  approve() {
    this.productVerificationService.triggerApproveClicked(this.params?.data?._id, this.params?.data);
  }

  reject(): void {
    if (this.additionalValue == 'PO') {
      const modalRef = this.modalService.open(ProductReportComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.productId = this.params.data?._id;
      modalRef.componentInstance.purchaseOrderId = this.params.data?.purchaseOrderId;
      modalRef.result.then((res: string) => {
        if (res !== 'confirm' && res !== 'cancel') {
          this.productVerificationService.triggerFinalizeListing();
        }
      });
    } else {
      this.productVerificationService.triggerRejectClicked(this.params?.data?._id);
    }
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
  public get shouldShowButtons(): boolean {
    if (this.additionalValue === 'PO') {
      return !this.isConfirmed && !this.isReported;
    } else if (this.additionalValue === 'departmentHead') {
      return !this.Approved && !this.Reject;
    }
    return false;
  }
  public get shouldShowApprove(): boolean {
    if (this.additionalValue === 'PO') {
      return this.isConfirmed && !this.isReported;
    } else if (this.additionalValue === 'departmentHead') {
      return this.Approved && !this.Reject;
    }
    return false;
  }

  public get shouldShowReport(): boolean {
    if (this.additionalValue === 'PO') {
      return this.isReported && !this.isConfirmed;
    } else if (this.additionalValue === 'departmentHead') {
      return this.Reject && !this.Approved;
    }
    return false;
  }
}
