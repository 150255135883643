<ng-select
  [items]="items"
  [multiple]="true"
  [bindLabel]="bindLabel"
  groupBy="selectedAllGroup"
  [selectableGroup]="true"
  [selectableGroupAsModel]="false"
  [closeOnSelect]="false"
  [bindValue]="bindValue"
  #inputName="ngModel"
  [(ngModel)]="selectedItems"
  (change)="onChange()"
  [required]="required"
  [disabled]="disabled"
  [placeholder]="placeholder"
>
  <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
    <input name="{{ item$.selected }}" id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
    {{ 'GENERAL.SELECTALL.TEXT' | translate }}
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input name="{{ item[bindValue] }}" id="{{ index[bindValue] }}" type="checkbox" [ngModel]="item$.selected" />
    {{ item[bindLabel] }}
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items.slice(0, maxRecords); let i = index">
      <div *ngIf="item[bindValue]">
        <span class="ng-value-label">{{ item[bindLabel] }}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
      </div>
    </div>
    <div class="ng-value" *ngIf="items.length > maxRecords">
      <span class="ng-value-label">{{ items.length - maxRecords }} {{ 'GENERAL.MORE.TEXT' | translate }}</span>
    </div>
  </ng-template>
</ng-select>
<app-form-error-tip *ngIf="required" [fieldModel]="inputName" [ngForm]="ngForm" [name]="name"></app-form-error-tip>
