<!-- Right Sidebar -->
<div class="right-bar" (clickOutside)="hide()" [exclude]="'.right-bar-toggle'">
  <div class="" style="height: 100%">
    <div class="rightbar-title d-flex align-items-center bg-dark p-3">
      <h5 class="m-0 me-2 text-white">Notification</h5>

      <a href="javascript:void(0);" class="right-bar-toggle-close right-bar-toggle ms-auto" (click)="hide()">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
    </div>
    <hr class="m-0" />
    <div style="max-height: 89vh; overflow-y: scroll">
      <a href="" class="text-reset notification-item" *ngFor="let message of notificationMessage">
        <div class="d-flex border-bottom align-items-start">
          <div class="flex-grow-1">
            <h6 class="mb-1">{{ message.productName }}</h6>
            <div class="text-muted">
              <p class="mb-1 font-size-13">{{ message.message }}</p>
              <p class="mb-0 font-size-10 text-uppercase fw-bold">
                <i class="mdi mdi-clock-outline"></i> {{ message.createdAt }}
              </p>
            </div>
          </div>
        </div>
      </a>
      <h4 class="text-reset notification-item text-center" *ngIf="notificationMessage?.length === 0">No Data found</h4>
    </div>
    <div
      *ngIf="notificationMessage?.length > 0"
      class="rightbar-title d-flex align-items-center bg-dark p-3 rightbar-footer"
      (click)="deleteAllNotification()"
    >
      <h5 class="text-white mx-auto cursor-pointer">Delete All</h5>
    </div>
  </div>
</div>
