import { IToken, TokenUser } from '@shared';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { User } from '../models/auth.models';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenKey = environment.tokenKey;
  private profileKey = environment.profileKey;
  constructor() {}

  setToken(tokens: IToken, isRememberMe: boolean = false) {
    if (isRememberMe) {
      localStorage.setItem(this.tokenKey, JSON.stringify(tokens));
    } else {
      sessionStorage.setItem(this.tokenKey, JSON.stringify(tokens));
    }
  }
  getTokens(): IToken {
    return JSON.parse(localStorage.getItem(this.tokenKey) ?? sessionStorage.getItem(this.tokenKey) ?? '{}');
  }

  setCurrentUser(userDetial: TokenUser, isRememberMe: boolean = false) {
    localStorage.removeItem(this.profileKey);
    sessionStorage.removeItem(this.profileKey);
    if (isRememberMe) {
      localStorage.setItem(this.profileKey, JSON.stringify(userDetial));
    } else {
      sessionStorage.setItem(this.profileKey, JSON.stringify(userDetial));
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem(this.profileKey) ?? sessionStorage.getItem(this.profileKey) ?? '{}');
  }
}
