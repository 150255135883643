import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductVerificationService {
  private approveClickedSubject = new Subject<{ id: string; data?: any }>();

  private rejectClickedSubject = new Subject<string>();
  private finalizeListingSubject = new Subject<void>();
  approveClicked$ = this.approveClickedSubject.asObservable();
  rejectClicked$ = this.rejectClickedSubject.asObservable();
  finalizeListing$ = this.finalizeListingSubject.asObservable();
  triggerApproveClicked(id: string, data?: any) {
    this.approveClickedSubject.next({ id, data });
  }
  triggerRejectClicked(id: string) {
    this.rejectClickedSubject.next(id);
  }
  triggerFinalizeListing() {
    this.finalizeListingSubject.next();
  }
}
