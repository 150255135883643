import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationParams } from '../interfaces/IPaginationParamas';
import { ApiResponse } from '@shared';
import { IDepartmentList } from '../interfaces/tables/IDepartmentList';
import { IProductList } from '../interfaces/tables/IProductList';
import { Observable } from 'rxjs';
import { IQuotationList } from '../interfaces/tables/IQuotationItem';

@Injectable({ providedIn: 'root' })
export class PurchaseProductService {
  constructor(private http: HttpClient) {}
  getAllPurchaseOrderListing(filterData: IPaginationParams) {
    let params = new HttpParams()
      .set('pageNum', filterData.pageNum.toString())
      .set('pageLimit', filterData.pageLimit.toString())
      .set('search', filterData.search ? filterData.search : '')
      .set('sortField', filterData.sortField ? filterData.sortField : '')
      .set('sortOrder', filterData.sortOrder ? filterData.sortOrder.toString() : '')
      .set('startDate', filterData.startDate ? filterData.startDate.toString() : '')
      .set('endDate', filterData.endDate ? filterData.endDate.toString() : '');
    filterData?.columnFilters?.forEach((ele) => {
      const [key, value] = Object.entries(ele)[0];
      params = params.set(key, value?.$regex);
    });
    return this.http.get<ApiResponse>('/v1/admin/purchase-order/purchase-order-listing', { params });
  }
  deletePurchaseProduct(_id: string) {
    return this.http.delete<ApiResponse>(`/v1/admin/purchase-order/delete-purchase-order/${_id}`);
  }
  GetAllPurchaseOrderProductListing(filterData: IPaginationParams, purchaseOrderId: string) {
    let params = new HttpParams()
      .set('pageNum', filterData.pageNum.toString())
      .set('pageLimit', filterData.pageLimit.toString())
      .set('search', filterData.search ? filterData.search : '')
      .set('sortField', filterData.sortField ? filterData.sortField : '')
      .set('sortOrder', filterData.sortOrder ? filterData.sortOrder.toString() : '')
      .set('purchaseOrderId', purchaseOrderId);
    filterData?.columnFilters?.forEach((ele) => {
      const [key, value] = Object.entries(ele)[0];
      params = params.set(key, value?.$regex);
    });
    return this.http.get<ApiResponse>('/v1/admin/purchase-order/product/purchase-order-product-listing', { params });
  }
  getPurchaseOrderProduct(_id: string, purchaseOrderId: string) {
    return this.http.get<ApiResponse>(
      `/v1/admin/purchase-order/product/${purchaseOrderId}/get-purchase-order-product/${_id}`,
    );
  }
  createPurchaseOrder(data: IDepartmentList) {
    return this.http.post<ApiResponse>(`/v1/admin/purchase-order/create-purchase-order`, data);
  }
  addProductInPurchaseOrder(data: IProductList, purchaseOrderId: string) {
    return this.http.post<ApiResponse>(
      `/v1/admin/purchase-order/product/add-prodcut-in-purchase-order/${purchaseOrderId}`,
      data,
    );
  }
  updatePurchaseOrderProduct(purchaseOrderId: string, data: IProductList, purchaseRequestId: string) {
    return this.http.put<ApiResponse>(
      `/v1/admin/purchase-order/product/${purchaseOrderId}/update-purchase-order-product/${purchaseRequestId}`,
      data,
    );
  }
  deletePurchaseOrderProduct(purchaseOrderId: string, _id: string) {
    return this.http.delete<ApiResponse>(
      `/v1/admin/purchase-order/product/${purchaseOrderId}/delete-purchase-order-product/${_id}`,
    );
  }
  exportPurchaseOrderProduct(params: any): Observable<HttpResponse<Blob>> {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json', // Specify 'blob' responseType
    };
    return this.http.post<HttpResponse<Blob>>(
      `/v1/admin/purchase-order/product/export-purchase-order-product`,
      params,
      options,
    );
  }
  purchaseOrderDetial(purchaseOrderId: string) {
    return this.http.get<ApiResponse>(`/v1/admin/purchase-order/purchase-order-detail/${purchaseOrderId}`);
  }
  generatePurchaseOrderPdf(data: any) {
    data.exportType = 'PDF';
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };
    return this.http.post<ApiResponse>(`/v1/admin/purchase-order/purchase-order-detail-pdf`, data, options);
  }
  productDropdown() {
    return this.http.get<ApiResponse>(`/v1/admin/purchase-order/product-dropdown`);
  }

  getActivityLoggerDetail(purchaseOrderId: string) {
    return this.http.get<ApiResponse>(`/v1/admin/purchase-order/activity-logger/${purchaseOrderId}`);
  }
  //-------------------------------------------------------------------------------------------------------------
  //QUATATION

  vendorListing(filterData: IPaginationParams, purchaseOrderId: string) {
    let params = new HttpParams()
      .set('pageNum', filterData.pageNum.toString())
      .set('pageLimit', filterData.pageLimit.toString())
      .set('search', filterData.search ? filterData.search : '')
      .set('sortField', filterData.sortField ? filterData.sortField : '')
      .set('sortOrder', filterData.sortOrder ? filterData.sortOrder.toString() : '')
      .set('purchaseOrderId', purchaseOrderId);
    filterData?.columnFilters?.forEach((ele) => {
      const [key, value] = Object.entries(ele)[0];
      params = params.set(key, value?.$regex);
    });
    return this.http.get<ApiResponse>('/v1/admin/quotation/vendor-listing', { params });
  }

  quotationListing(filterData: IPaginationParams, purchaseOrderId: string, vendorId: string) {
    let params = new HttpParams()
      .set('pageNum', filterData.pageNum.toString())
      .set('pageLimit', filterData.pageLimit.toString())
      .set('search', filterData.search ? filterData.search : '')
      .set('sortField', filterData.sortField ? filterData.sortField : '')
      .set('sortOrder', filterData.sortOrder ? filterData.sortOrder.toString() : '')
      .set('purchaseOrderId', purchaseOrderId)
      .set('vendorId', vendorId);
    filterData?.columnFilters?.forEach((ele) => {
      const [key, value] = Object.entries(ele)[0];
      params = params.set(key, value?.$regex);
    });
    return this.http.get<ApiResponse>('/v1/admin/quotation/get-quotation-listing', { params });
  }
  deleteQuotation(_id: string) {
    return this.http.delete<ApiResponse>(`/v1/admin/quotation/delete-quotation/${_id}`);
  }

  createQuoatation(data: IQuotationList, purchaseOrderId: string, vendorId: string) {
    return this.http.post<ApiResponse>(`/v1/admin/quotation/create-quotation`, { ...data, purchaseOrderId, vendorId });
  }

  quotationItemListing(_id: string) {
    const params = new HttpParams().set('_id', _id);
    return this.http.get<ApiResponse>('/v1/admin/quotation/get-quotation-listing', { params });
  }

  purchaseOrderProductDropdown(purchaseOrderId: string) {
    return this.http.get<ApiResponse>(`/v1/admin/purchase-order/product-dropdown/${purchaseOrderId}`);
  }

  updateQuotation(quotationId: string, data: IQuotationList) {
    return this.http.put<ApiResponse>(`/v1/admin/quotation/update-quotation/${quotationId}`, data);
  }

  finalizeVendor(purchaseOrderId: string, vendorId: string) {
    return this.http.post<ApiResponse>(`/v1/admin/quotation/finalize-vendor`, { purchaseOrderId, vendorId });
  }
  finalizeProduct(purchaseOrderId: string, vendorId: string, filterData: IPaginationParams) {
    let params = new HttpParams()
      .set('pageNum', filterData.pageNum.toString())
      .set('pageLimit', filterData.pageLimit.toString())
      .set('search', filterData.search ? filterData.search : '')
      .set('sortField', filterData.sortField ? filterData.sortField : '')
      .set('sortOrder', filterData.sortOrder ? filterData.sortOrder.toString() : '')
      .set('purchaseOrderId', purchaseOrderId)
      .set('vendorId', vendorId);
    filterData?.columnFilters?.forEach((ele) => {
      const [key, value] = Object.entries(ele)[0];
      params = params.set(key, value?.$regex);
    });
    return this.http.get<ApiResponse>('/v1/admin/quotation/finalize-product', { params });
  }
  productVerification(purchaseOrderId: string, productId: string, data: any) {
    return this.http.put<ApiResponse>(
      `/v1/admin/purchase-order/product/product-verification/${purchaseOrderId}/${productId}`,
      data,
    );
  }

  //update stage
  updatePurchaseOrder(purchaseOrderId: string, data: IProductList) {
    return this.http.put<ApiResponse>(`/v1/admin/purchase-order/udpate-purchase-order/${purchaseOrderId}`, data);
  }

  getWarrantyProduct(purchaseOrderId: string, productId: string, vendorId: string) {
    return this.http.get<ApiResponse>(
      `/v1/admin/purchase-order/warranty-product/${purchaseOrderId}/${productId}/${vendorId}`,
    );
  }

  productWarrentyEntry(purchaseOrderId: string, productId: string, vendorId: string, data: IProductList) {
    return this.http.put<ApiResponse>(
      `/v1/admin/purchase-order/product/product-warranty-entry/${purchaseOrderId}/${productId}/${vendorId}`,
      data,
    );
  }

  exportPurchaseOrderListing(params: IPaginationParams): Observable<HttpResponse<Blob>> {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };
    return this.http.post<HttpResponse<Blob>>(`/v1/admin/purchase-order/export-purchase-order`, params, options);
  }
  exportPurchaseOrder(data: any) {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json', // Specify 'blob' responseType
    };
    return this.http.post<HttpResponse<Blob>>(`/v1/admin/quotation/purchase-order-pdf`, data, options);
  }
  PurchaseOrderPdf(data: any) {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json', // Specify 'blob' responseType
    };
    return this.http.post<HttpResponse<Blob>>(`/v1/admin/quotation/export-purchase-order`, data, options);
  }

  confirmationMail(purchaseOrderId: string) {
    return this.http.get<ApiResponse>(`/v1/admin/quotation/confirmation-mail/${purchaseOrderId}`);
  }
}
