import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IProductList } from 'app/core/interfaces/tables/IProductList';
import { PurchaseProductService } from 'app/core/services/PurchaseProduct.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-report',
  templateUrl: './product-report.component.html',
  styleUrl: './product-report.component.scss',
})
export class ProductReportComponent {
  productId: string = '';
  purchaseOrderId: string = '';
  isLoading = false;
  ProductRequestData: IProductList = {} as IProductList;

  constructor(
    public modalServices: NgbActiveModal,
    private _toast: ToastrService,
    private translate: TranslateService,
    public purchaseProductService: PurchaseProductService,
  ) {}

  onClose(type: string): void {
    this.modalServices.close(type);
  }
  addReportIssue(reportItemIssue: NgForm): void {
    if (!reportItemIssue.valid) {
      return;
    }
    this.isLoading = true;
    if (this.productId) {
      const data = {
        reportedReason: reportItemIssue.value.reportedReason,
        isReported: true,
      };
      this.purchaseProductService.productVerification(this.purchaseOrderId, this.productId, data).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.modalServices.close('save');
        },
        error: (error: any) => {
          this._toast.error(error || this.translate.instant('COMMON.WENTWRONG.TEXT'), 'Error');
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }
}
