<div>
  <ng-container *ngIf="shouldShowButtons">
    <button
      title="Approve"
      class="btn btn-sm btn-outline-success mx-1"
      (click)="approve()"
      [disabled]="isBack"
      [ngClass]="{ disabled: !isWrite }"
    >
      <i-feather name="check"></i-feather>
    </button>
    <button
      title="Reject"
      class="btn btn-sm btn-outline-danger mx-1"
      (click)="reject()"
      [disabled]="isBack"
      [ngClass]="{ disabled: !isWrite }"
    >
      <i-feather name="alert-triangle"></i-feather>
    </button>
  </ng-container>

  <ng-container *ngIf="shouldShowApprove">
    <h5
      class="m-0 badge py-2 statusFont"
      [ngClass]="{ 'badge-soft-success': isConfirmed === true || Approved === true }"
    >
      Approve
    </h5>
  </ng-container>

  <ng-container *ngIf="shouldShowReport">
    <h5 class="m-0 badge py-2 statusFont" [ngClass]="{ 'badge-soft-danger': isReported === true || Reject === true }">
      {{ isReported === true ? 'Report' : Reject === true ? 'Reject' : '' }}
    </h5>
  </ng-container>
</div>
