import { DatePipe } from '@angular/common';
import { ElementRef, Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CustomvalidationService {
  constructor(private datePipe: DatePipe) {}
  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return {};
      }
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
      const valid = regex.test(control.value);
      return valid ? {} : { invalidPassword: true };
    };
  }

  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup): any => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return {};
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordMismatch']) {
        return {};
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      }
    };
  }

  userNameValidator(userControl: AbstractControl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.validateUserName(userControl.value)) {
          resolve({ userNameNotAvailable: true });
        } else {
          resolve({});
        }
      }, 1000);
    });
  }

  validateUserName(userName: string) {
    const UserList = ['ankit', 'admin', 'user', 'superuser'];
    return UserList.indexOf(userName) > -1;
  }

  alphabetsOnly(elRef: ElementRef): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return {};
      }
      const regex = new RegExp('^[a-zA-Z\u0590-\u05FF ]*$');

      const valid = regex.test(control.value);

      return valid ? {} : { alphabetsOnly: true };
    };
  }

  alphaNumeric(elRef: ElementRef): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return {};
      }
      const regex = new RegExp('^[a-z0-9]+$');
      const valid = regex.test(control.value);

      return valid ? {} : { invalid: true };
    };
  }

  countryCode(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return {};
      }
      const regex = /^(\+?\d{1,3}|\d{1,4})$/gm;
      const valid = regex.test(control.value);
      return valid ? {} : { invalid: true };
    };
  }

  numbersOnly(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return {};
      }
      const regex = /^[0-9]+$/;
      const valid = regex.test(control.value) || Number(control.value) > 0;
      return valid ? {} : { numbersOnly: true };
    };
  }
  customPhoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // If the control is empty, consider it valid
      }

      const regex = /^[0-9]{10}$/; // Ensure exactly 10 digits

      const valid = regex.test(control.value) && Number(control.value) > 0;

      return valid ? null : { invalidPhoneNumber: true };
    };
  }

  timeInvalid(minDate: string, apply: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value || !apply) {
        return {};
      }

      const valid = moment(moment(minDate, 'hh:mm')).isBefore(moment(moment(control.value, 'hh:mm')));
      return valid ? {} : { timeisInvalid: true, incorrect: true };
    };
  }
  customPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$/;

      const valid = regex.test(control.value);

      return valid ? {} : { invalidPassword: true };
    };
  }
  trimAndValidate(controlName: string, form: FormGroup): boolean {
    const control = form.controls[controlName];
    if (control.value !== undefined) {
      const trimmedValue = control.value.trim();
      control.patchValue(trimmedValue);
      if (!trimmedValue) {
        control.setErrors({ required: true });
        return false;
      }
    }
    return true;
  }
}
