import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class TopbarService {
  constructor(private http: HttpClient) {}
  getNotificationCount() {
    return this.http.get<ApiResponse>('/v1/admin/notification/notification-count');
  }
  getNotificationListing() {
    return this.http.get<ApiResponse>('/v1/admin/notification/notification-listing');
  }
  deleteAllNotification() {
    return this.http.delete<ApiResponse>('/v1/admin/notification/delete-all-notification');
  }
}
