import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic500',
  templateUrl: './basic500.component.html',
  styleUrls: ['./basic500.component.scss'],
})

/**
 * Basic 500 component
 */
export class Basic500Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
