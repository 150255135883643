import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cover500',
  templateUrl: './cover500.component.html',
  styleUrls: ['./cover500.component.scss'],
})

/**
 * Cover 500 Component
 */
export class Cover500Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
