export class BreadCrumbHeader {
  title: string = '';
  breadCrumb: BreadCrumb[] = [];
}

export class BreadCrumb {
  name: string = '';
  routeUrl: any[] = [];
  isActive?: boolean;
}
