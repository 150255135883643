import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LAYOUT_DIRECTION, SIDEBAR_COLOR, TOPBAR } from 'app/layouts/layouts.model';
import { CookieService } from 'ngx-cookie-service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutSettingsService {
  layoutSetting: LayoutSettings = {
    sidebarcolor: SIDEBAR_COLOR,
    topbar: TOPBAR,
    direction: LAYOUT_DIRECTION,
  };
  constructor(
    private _cookieService: CookieService,
    private eventService: EventService,
  ) {}
  setDefaultOptions(): void {
    if (!Object.keys(this.getCurrentLayoutSettings()).length) {
      this.setLayoutSettings();
    }
    const settingsFromCookie: LayoutSettings = this.getCurrentLayoutSettings();
    this.layoutSetting.topbar = settingsFromCookie.topbar ?? TOPBAR;
    this.layoutSetting.sidebarcolor = settingsFromCookie.sidebarcolor ?? SIDEBAR_COLOR;
    this.layoutSetting.direction = settingsFromCookie.direction ?? LAYOUT_DIRECTION;
    this.setLayoutSettings();
    this.Topbar(this.layoutSetting.topbar);
    this.SidebarColor(this.layoutSetting.sidebarcolor);
    this.changeDirection(this.layoutSetting.direction);
    this.eventService.subscribe('changeTopbar', (topbar) => {
      this.layoutSetting.topbar = topbar;
      this.Topbar(this.layoutSetting.topbar);
      this.setLayoutSettings();
    });

    this.eventService.subscribe('changeSidebarColor', (sidebarcolor) => {
      this.layoutSetting.sidebarcolor = sidebarcolor;
      this.SidebarColor(this.layoutSetting.sidebarcolor);
      this.setLayoutSettings();
    });
    this.eventService.subscribe('changeDirection', (directionOfLayout) => {
      this.layoutSetting.direction = directionOfLayout;
      this.changeDirection(this.layoutSetting.direction);
      this.setLayoutSettings();
    });
  }

  getCurrentLayoutSettings() {
    const currenSettings = this._cookieService.get(environment.layoutSettingsKey);
    return JSON.parse(currenSettings && currenSettings.trim() !== '' ? currenSettings : '{}');
  }
  getCurrentDirection() {
    return this.getCurrentLayoutSettings().direction ?? 'ltr';
  }
  setLayoutSettings() {
    this._cookieService.set(environment.layoutSettingsKey, JSON.stringify(this.layoutSetting), {
      expires: environment.cookieExpireTime,
      path: '/',
      secure: false,
    });
  }
  Topbar(topbar: string) {
    switch (topbar) {
      case 'light':
        document.body.setAttribute('data-topbar', 'light');
        break;
      case 'dark':
        document.body.setAttribute('data-topbar', 'dark');
        break;
      default:
        document.body.setAttribute('data-topbar', 'light');
        break;
    }
  }
  SidebarColor(color: string) {
    switch (color) {
      case 'light':
        document.body.setAttribute('data-sidebar', 'light');
        break;
      case 'dark':
        document.body.setAttribute('data-sidebar', 'dark');
        break;
      case 'brand':
        document.body.setAttribute('data-sidebar', 'brand');
        break;
      default:
        document.body.setAttribute('data-sidebar', 'light');
        break;
    }
  }
  changeDirection(direction: string) {
    switch (direction) {
      case 'ltr':
        document.documentElement.removeAttribute('dir');
        break;
      case 'rtl':
        document.documentElement.setAttribute('dir', 'rtl');
        break;
      default:
        document.documentElement.removeAttribute('dir');
        break;
    }
  }
}

export interface LayoutSettings {
  topbar: string;
  sidebarcolor: string;
  direction: string;
}
