<div *ngIf="(fieldModel.touched || ngForm.submitted) && fieldModel.errors" class="validationError">
  <span *ngIf="fieldModel.errors.required">{{ name }} {{ 'VALIDATORS.IS_REQUIRED.TEXT' | translate }}</span>
  <span *ngIf="fieldModel.errors.pattern">{{ name }} {{ 'VALIDATORS.REQUIREDPATTEREN.TEXT' | translate }}</span>
  <span *ngIf="fieldModel.errors?.numbersOnly">{{ 'VALIDATORS.NUMBER.TEXT' | translate }}</span>
  <span *ngIf="fieldModel.errors?.alphabetsOnly">{{ 'VALIDATORS.ALPHABET.TEXT' | translate }}</span>
  <span *ngIf="fieldModel.errors?.email">{{ 'VALIDATORS.VALID.TEXT' | translate }}</span>
  <span *ngIf="fieldModel.errors?.timeisInvalid">{{ 'VALIDATORS.VALID_TIME.TEXT' | translate }}</span>
  <span *ngIf="fieldModel.errors?.passwordMismatch">{{ 'USER.PASSWORDDOSENTMATHCES.TEXT' | translate }}</span>
  <!-- <span *ngIf="fieldModel.errors.pattern">{{ 'VALIDATORS.PATTERN.TEXT' | translate }}</span> -->
  <span *ngIf="fieldModel.errors?.invalidPassword"
    >{{ 'USER.PASSWORD.TEXT' | translate }} {{ 'VALIDATORS.IS_INVALID.TEXT' | translate }}</span
  >
  <span *ngIf="fieldModel.errors?.invalidPhoneNumber"
    >{{ 'ADMIN.ADMINFORM.PHONE.TEXT' | translate }} {{ 'VALIDATORS.PHONENUMBER.TEXT' | translate }}</span
  >
</div>
