<app-bread-crumb [breadCrumbHeader]="breadCrumbHeader"></app-bread-crumb>
<div class="container-fluid mt-4 bg-white p-4 rounded shadow-sm">
  <h4 class="mb-4">Report List</h4>
  <div class="row">
    <div class="col-md-3" *ngFor="let report of reports">
      <div
        class="card text-white"
        [ngStyle]="{ backgroundColor: report.backgroundColor }"
        (click)="onReportClick(report.name)"
      >
        <div class="card-body d-flex align-items-center justify-content-center">
          <div class="text-center">
            <i [ngClass]="report.icon" [ngStyle]="{ color: report.color }" class="fa-2x mb-3 icon-with-shadow"></i>
            <h5 [ngStyle]="{ color: report.color }">{{ report.name }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
