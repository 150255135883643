<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="myModalLabel">
    {{ 'PURCHASEPRODUCT.PRODUCTREPORT.TEXT' | translate }}
  </h5>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="onClose('cancel')"
  ></button>
</div>
<div class="modal-body">
  <form
    #reportIssueFormsData="ngForm"
    class="needs-validation was-validated"
    (ngSubmit)="addReportIssue(reportIssueFormsData)"
    [ngClass]="{ 'was-validated': reportIssueFormsData.submitted }"
    novalidate
  >
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <label class="form-label required"> {{ 'PURCHASEPRODUCT.REPORTISSUE.TEXT' | translate }} </label>
          <app-common-loading [count]="1" [appearance]="'line'" [isLoading]="isLoading">
            <textarea
              class="form-control"
              required
              placeholder="{{ 'PURCHASEPRODUCT.ENTERREPORTISSUE.TEXT' | translate }}"
              name="reportedReason"
              [(ngModel)]="ProductRequestData.reportedReason"
              #reportedReason="ngModel"
            ></textarea>
          </app-common-loading>
          <app-form-error-tip
            [fieldModel]="reportedReason"
            [ngForm]="reportIssueFormsData"
            [name]="'PURCHASEPRODUCT.REPORTISSUE.TEXT' | translate"
          ></app-form-error-tip>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="onClose('cancel')">
        {{ 'COMMON.CLOSE.TEXT' | translate }}
      </button>
      <button type="submit" class="btn btn-info">{{ 'COMMON.SAVE.TEXT' | translate }}</button>
    </div>
  </form>
</div>
