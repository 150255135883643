<div class="authentication-bg min-vh-100">
  <div class="bg-overlay bg-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="d-flex flex-column min-vh-100 px-3 pt-4">
          <div class="text-center my-auto py-5">
            <div class="mb-4 mb-md-5">
              <a routerLink="/" class="d-block auth-logo">
                <img src="assets/images/logo-dark.png" alt="" height="22" class="auth-logo-dark" />
                <img src="assets/images/logo-light.png" alt="" height="22" class="auth-logo-light" />
              </a>
            </div>
            <div class="text-muted mb-5">
              <h4>Let's get started with Dashonic</h4>
              <p>It will be as simple as Occidental in fact it will be Occidental.</p>
            </div>

            <div id="countdown" class="countdownlist">
              <div class="countdownlist-item">
                <div class="count-title">Days</div>
                <div class="count-num">{{ _days }}</div>
              </div>
              <div class="countdownlist-item">
                <div class="count-title">Hours</div>
                <div class="count-num">{{ _hours }}</div>
              </div>
              <div class="countdownlist-item">
                <div class="count-title">Minutes</div>
                <div class="count-num">{{ _minutes }}</div>
              </div>
              <div class="countdownlist-item">
                <div class="count-title">Seconds</div>
                <div class="count-num">{{ _seconds }}</div>
              </div>
            </div>

            <div class="input-group countdown-input-group mx-auto my-5">
              <input
                type="email"
                class="form-control border-light shadow"
                placeholder="Enter your email address"
                aria-label="search result"
                aria-describedby="button-email"
              />
              <button class="btn btn-primary" type="button" id="button-email">
                Send <i class="bx bx-paper-plane ms-1"></i>
              </button>
            </div>
          </div>

          <div class="text-center p-4">
            <p class="mb-0">
              &copy; {{ year }} Dashonic. Crafted with <i class="mdi mdi-heart text-danger"></i> by Pichforest
            </p>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
