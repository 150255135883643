import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumbHeader } from 'app/core/models/bread-crumb.model';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss',
})
export class ReportsComponent implements OnInit {
  breadCrumbHeader: BreadCrumbHeader = new BreadCrumbHeader();

  constructor(private router: Router) {}

  reports = [
    { name: 'Purchased Report', icon: 'fa fa-shopping-cart', backgroundColor: '#FFE5E5', color: '#F37C7C' },
    { name: 'Department Transfer Report', icon: 'fa fa-exchange-alt', backgroundColor: '#F7ECFF', color: '#CA85FD' },
    { name: 'Stock Report', icon: 'fa fa-warehouse', backgroundColor: '#E5EBFF', color: '#6686FF' },
    { name: 'Goods Received Note', icon: 'fa fa-receipt', backgroundColor: '#CFFFF7', color: '#3C9787' },
    { name: 'Party Detail', icon: 'fa fa-address-book', backgroundColor: '#E6FFDA', color: '#65B53F' },
    { name: 'Purchase Order Generator', icon: 'fa fa-file-alt', backgroundColor: '#FFF3C9', color: '#E2AF00' },
    { name: 'Item Ledger', icon: 'fa fa-book', backgroundColor: '#FFE5DA', color: '#E5865E' },
    {
      name: 'Department Transfer & Purchase Register',
      icon: 'fa fa-book-open',
      backgroundColor: '#FFDDF1',
      color: '#DC66AB',
    },
  ];

  ngOnInit() {
    this.initBreadCrumbs();
  }

  initBreadCrumbs() {
    this.breadCrumbHeader.title = 'MENUITEMS.REPORTS.TEXT';
    this.breadCrumbHeader.breadCrumb.push(
      {
        name: 'MENUITEMS.DASHBOARD.TEXT',
        routeUrl: [''],
        isActive: true,
      },
      {
        name: 'MENUITEMS.REPORTS.TEXT',
        routeUrl: [],
        isActive: false,
      },
    );
  }

  onReportClick(reportName: string) {
    switch (reportName) {
      case 'Purchased Report':
        this.router.navigate(['/purchase-product/list/'], {
          state: { isReport: true },
        });
        break;
      case 'Stock Report':
        this.router.navigate(['/report/store-report']);
        break;
      case 'Party Detail':
        this.router.navigate(['/vendor/list'], {
          state: { isReport: true },
        });
        break;
      case 'Item Ledger':
        this.router.navigate(['/purchase-ledger/list']);
        break;
      case 'Department Transfer & Purchase Register':
        this.router.navigate(['/department-transfer/list']);
        break;
      case 'Department Transfer Report':
        this.router.navigate(['/department-transfer/list']);
        break;
      default:
        break;
    }
  }
}
