import { createReducer, on } from '@ngrx/store';
import { createPermission, loadPermissions, loadpermissionsuccess } from './permission.actions';
import { PermissionState } from './permission.state';

const _PermissionReducer = createReducer(
  PermissionState,
  on(loadPermissions, (state) => {
    return {
      ...state,
    };
  }),
  on(loadpermissionsuccess, (state, action) => {
    return {
      ...state,
      permissionList: [...action.permissionList.data],
    };
  }),
  on(createPermission, (state, action) => {
    const _permission = { ...action.permissioninput };
    return {
      ...state,
      permissionList: [...state.permissionList, _permission],
    };
  }),
);

export function PermissionReducer(state: any, action: any) {
  return _PermissionReducer(state, action);
}
