<div class="vertical-menu">
  <div class="navbar-brand-box p-0 px-2">
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img [src]="mainLogoSmall" alt="" class="w-75" />
      </span>
      <span class="logo-lg">
        <img [src]="mainLogo" alt="" class="w-75 pe-3" />
      </span>
    </a>

    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <img [src]="mainLogoSmall" alt="" class="w-75" />
      </span>
      <span class="logo-lg">
        <img [src]="mainLogoDark" alt="" class="w-75 pe-3" />
      </span>
    </a>
  </div>

  <button
    type="button"
    class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
    (click)="toggleMobileMenu($event)"
  >
    <i class="fa fa-fw fa-bars"></i>
  </button>
  <ngx-simplebar class="sidebar-menu-scroll" style="max-height: 100vh">
    <div id="sidebar-menu" class="position-relative">
      <ul class="metismenu list-unstyled" id="side-menu">
        <ng-container *ngFor="let item of menuItems">
          <li *ngIf="item.link !== ''">
            <a
              *ngIf="hasItems(item)"
              href="javascript:void(0);"
              class="is-parent"
              [ngClass]="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
            >
              <i-feather name="{{ item.icon }}" class="me-1 nav-icon"></i-feather>
              <span class="menu-item"> {{ item.moduleKey ?? '' | translate }}</span>
            </a>
            <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
              <i-feather name="{{ item.icon }}" *ngIf="item.icon" class="me-1 nav-icon"></i-feather>
              <span class="menu-item"> {{ item.moduleKey ?? '' | translate }}</span>
            </a>
            <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li *ngFor="let subitem of item.submodules">
                <a
                  [routerLink]="subitem.link"
                  *ngIf="!hasItems(subitem)"
                  class="side-nav-link-ref"
                  [attr.data-parent]="item._id"
                  routerLinkActive="active"
                >
                  <i-feather name="{{ subitem.icon }}" *ngIf="subitem.icon" class="me-1 nav-icon nav-list"></i-feather>
                  {{ subitem.moduleKey | translate }}
                </a>
                <a
                  *ngIf="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  [attr.data-parent]="item._id"
                >
                  {{ subitem.moduleKey | translate }}
                </a>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </ngx-simplebar>
</div>
