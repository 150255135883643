import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from 'app/core/services/token.service';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss'],
})
export class ImportModalComponent implements OnInit {
  @Input() sampleFileUrl: string = '';
  @Input() isStore: boolean = false;
  @Input() importURL: string = '';
  @Input() headerText: string = '';
  @ViewChild('dz') drpzone!: DropzoneComponent;
  file: any = null;
  removeFile: boolean = false;
  message: string = `<div>
  <i class="uil uil-cloud-upload text-primary fs-2"></i>
  <div class="dropzonePlaceholder">
    ${this.translate.instant('DROPZONE.SELECTORDRAG.TEXT')}
  </div>
</div>`;

  constructor(
    private _toast: ToastrService,
    private translate: TranslateService,
    public ts: TokenService,
    public activeModal: NgbActiveModal,
  ) {}
  public config: DropzoneConfigInterface = {
    clickable: true,
    autoReset: null,
    errorReset: null,
    maxFiles: 1,
    paramName: 'csvFile',
    params: {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    },
    headers: { Authorization: 'Bearer ' + this.ts.getTokens().accessToken },
    acceptedFiles: '.csv',
    url: 'https://httpbin.org/post',
    autoProcessQueue: false,
  };

  ngOnInit(): void {
    if (this.isStore == true) {
      this.config.params.isStoreCategory = true;
    }
    this.config.url = `${environment.apiUrl}${this.importURL}`;
  }

  onClose(type: string) {
    this.activeModal.close(type);
  }

  SampleFile() {
    const link = document.createElement('a');
    link.setAttribute('href', this.sampleFileUrl);
    link.setAttribute('download', 'SampleFile.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  onUploadFile(event: any) {
    this.checkResponse(event[1]);
    this.removeFile = true;
    this.onClose('confirm');
  }
  onUploadError(error: any): void {
    this.removeFile = true;
    this._toast.error(this.translate.instant(JSON.stringify(error[1].message)));
  }
  RemoveFile() {
    this.file = null;
    this.drpzone?.directiveRef?.reset();
    this.drpzone?.directiveRef?.dropzone();
    this.removeFile = false;
  }
  importFile() {
    this.drpzone.directiveRef?.dropzone().processQueue();
  }

  checkResponse(response: any) {
    if (response.data) {
      this._toast.success(this.translate.instant(response.message));
    } else {
      this._toast.error(this.translate.instant('WRONGFILEERROR.TEXT'));
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(
        new Blob([response as Blob], {
          type: 'text/csv',
        }),
      );
      link.download = `WrongFile.csv`;
      link.click();
    }
  }
}
