import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { WidgetModule } from './widget/widget.module';
import { DatatableComponent } from './datatable/datatable.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CommonLoadingComponent } from './common-loading/common-loading.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ActionCellRendererComponent } from './cell-renderers/action-cell-renderer/action-cell-renderer.component';
import { StatusCellRendererComponent } from './cell-renderers/status-cell-renderer/status-cell-renderer.component';
import { allIcons } from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';
import { RouterModule } from '@angular/router';
import { ImageCellRendererComponent } from './cell-renderers/image-cell-renderer/image-cell-renderer.component';

import { FromErrorComponent } from './from-error/from-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { MultivalueCellRendererComponent } from './cell-renderers/multivalue-cell-renderer/multivalue-cell-renderer.component';
import { ConstructImageCellRendererComponent } from './cell-renderers/construct-image-cell-renderer/construct-image-cell-renderer.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ImportModalComponent } from './import-modal/import-modal.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { CommonDropzoneComponent } from './dropzone/dropzone.component';
import { QuotationStatusCellRendererComponent } from './cell-renderers/quotation-status-cell-renderer/quotation-status-cell-renderer.component';
import { BillingModule } from 'app/pages/billing/billing.module';
@NgModule({
  declarations: [
    PagetitleComponent,
    DatatableComponent,
    //ListComponent,
    CommonLoadingComponent,
    ActionCellRendererComponent,
    StatusCellRendererComponent,
    ImageCellRendererComponent,
    FromErrorComponent,
    BreadCrumbComponent,
    MultivalueCellRendererComponent,
    ConstructImageCellRendererComponent,
    ConfirmationModalComponent,
    ImportModalComponent,
    MultiSelectDropdownComponent,
    CommonDropzoneComponent,
    QuotationStatusCellRendererComponent,
  ],
  imports: [
    CommonModule,
    WidgetModule,
    AgGridModule,
    NgbPaginationModule,
    NgSelectModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    FlatpickrModule.forRoot(),
    FeatherModule.pick(allIcons),
    RouterModule,
    TranslateModule,
    DropzoneModule,
  ],
  exports: [
    PagetitleComponent,
    DatatableComponent,
    CommonLoadingComponent,
    ActionCellRendererComponent,
    FromErrorComponent,
    StatusCellRendererComponent,
    BreadCrumbComponent,
    ImportModalComponent,
    MultiSelectDropdownComponent,
    CommonDropzoneComponent,
    QuotationStatusCellRendererComponent,
    DropzoneModule,
  ],
})
export class SharedModule {}
