<div class="maintenance">
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex flex-column min-vh-100">
        <div class="text-center my-auto py-5">
          <div class="row justify-content-center mb-5">
            <div class="col-sm-4">
              <div class="maintenance-img">
                <img src="assets/images/maintenance.png" alt="" class="img-fluid mx-auto d-block" />
              </div>
            </div>
          </div>
          <div class="text-muted mb-5">
            <h4>{{ 'MAINTENANCE' | translate }}</h4>
          </div>
          <!-- end row -->
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</div>
<ng-content></ng-content>
