import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationParams } from '../interfaces/IPaginationParamas';
import { ApiResponse } from '@shared';
import { IBillingList } from '../interfaces/tables/IBillingList';
import { IQuotationList } from '../interfaces/tables/IQuotationItem';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private http: HttpClient) {}

  getBillingListing(filterData: IPaginationParams) {
    let params = new HttpParams()
      .set('pageNum', filterData.pageNum.toString())
      .set('pageLimit', filterData.pageLimit.toString())
      .set('search', filterData.search ? filterData.search : '')
      .set('sortField', filterData.sortField ? filterData.sortField : '')
      .set('sortOrder', filterData.sortOrder ? filterData.sortOrder.toString() : '');
    filterData?.columnFilters?.forEach((ele) => {
      const [key, value] = Object.entries(ele)[0];
      params = params.set(key, value?.$regex);
    });
    return this.http.get<ApiResponse>('/v1/admin/billing/billing-listing', { params });
  }

  deleteBilling(_id: string) {
    return this.http.delete<ApiResponse>(`/v1/admin/billing/delete-billing/${_id}`);
  }

  getAllPurchaseOrderListing(filterData: IPaginationParams) {
    let params = new HttpParams()
      .set('pageNum', filterData.pageNum.toString())
      .set('pageLimit', filterData.pageLimit.toString())
      .set('search', filterData.search ? filterData.search : '')
      .set('sortField', filterData.sortField ? filterData.sortField : '')
      .set('sortOrder', filterData.sortOrder ? filterData.sortOrder.toString() : '')
      .set('isConfirmed', 'true');
    filterData?.columnFilters?.forEach((ele) => {
      const [key, value] = Object.entries(ele)[0];
      params = params.set(key, value?.$regex);
    });
    return this.http.get<ApiResponse>('/v1/admin/purchase-order/purchase-order-listing', { params });
  }
  getAllPurchaseOrderListingById(id: string) {
    return this.http.get<ApiResponse>('/v1/admin/billing/billing-listing-by-id/' + id);
  }

  generatePdf(data: any) {
    data.exportType = 'PDF';
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };
    return this.http.post<ApiResponse>(`/v1/admin/billing/generate-pdf`, data, options);
  }
  addPOinBill(data: IBillingList) {
    return this.http.post<ApiResponse>(`/v1/admin/billing/new-billing/save-bill`, data);
  }
  createNewBill(data: any) {
    return this.http.post<ApiResponse>(`/v1/admin/billing/new-billing/create-new-bill?accessLevel=public`, data);
  }
  udpateNewBillProductData(_id: string, data: Record<string, any>) {
    return this.http.put<ApiResponse>(
      `/v1/admin/billing/new-billing/udpated-new-bill-product/${_id}?accessLevel=public`,
      data,
    );
  }
  newBillAddPurchaseOrder(data: IQuotationList, purchaseOrderId: string) {
    return this.http.post<ApiResponse>(
      `/v1/admin/billing/new-billing/add-prodcut-in-purchase-order/${purchaseOrderId}`,
      data,
    );
  }

  newBillGetPurchaseOrderProduct(purchaseOrderId: string, _id: string) {
    return this.http.get<ApiResponse>(
      `/v1/admin/billing/new-billing/${purchaseOrderId}/get-purchase-order-product/${_id}`,
    );
  }
  getAllpurhcaseOrderDetail(purchaseOrderId: string) {
    return this.http.get<ApiResponse>(`/v1/admin/billing/purhcase-order-detail/${purchaseOrderId}`);
  }

  purchaseOrderProductListing(newBillId: string) {
    return this.http.get<ApiResponse>(`/v1/admin/billing/new-billing/get-product/${newBillId}`);
  }

  purchaseOrderProductItemListing(_id: string) {
    return this.http.get<ApiResponse>(`/v1/admin/billing/new-billing/get-product-by-id/${_id}`);
  }

  deletePurchaseOrderProduct(_id: string) {
    return this.http.delete<ApiResponse>(`/v1/admin/billing/new-billing/delete-product/${_id}`);
  }

  updatePurchaseOrderProduct(_id: string, data: IQuotationList) {
    return this.http.put<ApiResponse>(`/v1/admin/billing/new-billing/udpated-product/${_id}`, data);
  }

  addProductInPurchaseOrder(data: IQuotationList, newBillId: string) {
    return this.http.put<ApiResponse>(`/v1/admin/billing/new-billing/add-product/${newBillId}`, {
      ...data,
    });
  }

  getBillProductData(newBillId: string) {
    return this.http.get<ApiResponse>(`/v1/admin/billing/new-billing/get-new-bill-product/${newBillId}`);
  }

  transferStock(id: any) {
    return this.http.post<ApiResponse>('/v1/admin/billing/new-billing/transfer-stock', { id });
  }
  exportBill(params: any): Observable<HttpResponse<Blob>> {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };
    return this.http.post<HttpResponse<Blob>>(`/v1/admin/billing/export-bill`, params, options);
  }

  getImageUrl(newBillId: string) {
    return this.http.get<ApiResponse>(`/v1/admin/billing/bill-img/${newBillId}`);
  }
}
