import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports/reports.component';

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'vendor',
    loadChildren: () => import('./vendor/vendor.module').then((m) => m.VendorModule),
  },
  {
    path: 'purchase-product',
    loadChildren: () => import('./purchase-product/purchase-product.module').then((m) => m.PurchaseProductModule),
  },
  {
    path: 'billing',
    loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule),
  },
  {
    path: 'store',
    loadChildren: () => import('./store/store.module').then((m) => m.StoreModule),
  },
  {
    path: 'department-head',
    loadChildren: () => import('./department-head/department-head.module').then((m) => m.DepartmentHeadModule),
  },
  {
    path: 'store-entry',
    loadChildren: () => import('./store-entry/store-entry.module').then((m) => m.StoreEntryModule),
  },
  {
    path: 'welcome-office',
    loadChildren: () => import('./welcome-office/welcome-office.module').then((m) => m.WelcomeOfficeModule),
  },
  {
    path: 'final-authority',
    loadChildren: () => import('./final-authority/final-authority.module').then((m) => m.FinalAuthorityModule),
  },
  {
    path: 'account-department',
    loadChildren: () => import('./account-department/account-department.module').then((m) => m.AccountDepartmentModule),
  },
  {
    path: 'department-transfer',
    loadChildren: () =>
      import('./department-transfer/department-transfer.module').then((m) => m.DepartmentTransferModule),
  },
  {
    path: 'purchase-ledger',
    loadChildren: () => import('./purchase-ledger/purchase-ledger.module').then((m) => m.PurchaseLedgerModule),
  },
  {
    path: 'bill-ledger',
    loadChildren: () => import('./bill-legder/bill-legder.module').then((m) => m.BillLegderModule),
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then((m) => m.SettingModule),
  },
  {
    path: 'report',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
