import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/core/services/language.service';
import { LayoutSettingsService } from 'app/core/services/layout-settings.service';

@Component({
  selector: 'app-authlayout',
  templateUrl: './authlayout.component.html',
  styleUrls: ['./authlayout.component.scss'],
})
export class AuthlayoutComponent implements OnInit {
  logoUrl: string = 'assets/images/logo/logo.png';
  constructor(
    private _layout: LayoutSettingsService,
    private _language: LanguageService,
  ) {}
  ngOnInit(): void {
    this._language.setLanguage(this._language.getCurrentLang());
    this._layout.setDefaultOptions();
  }
}
