export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  tokenKey: 'fd-tokens',
  profileKey: 'user',
  layoutSettingsKey: 'fd-layout',
  languageCookieKey: 'lang',
  permissionKey: 'permissionKey',
  userPermissionsKey: 'userPermissionsKey',
  cookieExpireTime: 30 * 24 * 60 * 60,
  apiUrl: 'http://api.sgvp.shaligraminfotech.com/api',
  publicUrl: 'http://api.sgvp.shaligraminfotech.com/',
  imageUrl: 'https://tzomet-books-assets.s3.il-central-1.amazonaws.com/',
  awsenv: 'stage',
  link: 'https://tzomet.page.link/PZXe?status=false',
  androidPackageName: 'com.co.tzomet.books',
  iosBundleId: 'com.deepLinkingProjectBundleId',
  Key: 'AIzaSyB6ScbsrpUC5OQRUGb5qEY_htcCWG7q1Xg',
};
