import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import MetisMenu from 'metismenujs';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModulesService, PermissionsService, MenuItem, TokenUser } from '@shared';
import { TokenService } from 'app/core/services/token.service';
import { environment } from '@env/environment';
import { AuthenticationService } from 'app/core/services/auth.service';
import { Subject } from 'rxjs';
import { permissionModel } from '@shared/store/permission/permission.model';
import { Store } from '@ngrx/store';
import { loadPermissions } from '@shared/store/permission/permission.actions';
import { getPermission } from '@shared/store/permission/permission.selector';
import { IPaginationParams } from '@interfaces';
import { EventService } from 'app/core/services/event.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @ViewChild('sideMenu') sideMenu!: ElementRef;
  eventsSubject: Subject<void> = new Subject<void>();
  menu: any;
  menuItems: any;
  permissionKey: string = environment.permissionKey;
  baseUrl = environment.publicUrl + 'public/';
  mainLogo = 'assets/images/logo.png';
  mainLogoDark = 'assets/images/logo/main_logo.png';
  mainLogoSmall = 'assets/images/collapseLogo.png';
  currentTheme: string | null = null;
  _currentUser: TokenUser | undefined;
  permissionList!: permissionModel[];
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    private router: Router,
    public translate: TranslateService,
    public _moduleService: ModulesService,
    public _tokenService: TokenService,
    public _permissionService: PermissionsService,
    private _authService: AuthenticationService,
    private _router: Router,
    private store: Store<{ permission: permissionModel[] }>,
    private _eventService: EventService,
  ) {
    translate.setDefaultLang('en');
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });
  }
  filterParams: IPaginationParams = {
    pageLimit: 1000,
    pageNum: 1,
    sortField: '',
    sortOrder: '',
    search: '',
    columnFilters: [],
    forSidebar: true,
  };
  ngOnInit(): void {
    this.getAllModules(this.filterParams);
    this._currentUser = this._tokenService.getCurrentUser();

    this._eventService.subscribe('sideBarData', (data) => {
      this.getAllModules(this.filterParams);
    });
  }

  getAllModules(params: IPaginationParams) {
    this.eventsSubject.next();
    this._moduleService.getAllModules(this.filterParams).subscribe({
      next: (response) => {
        this.menuItems = response.data;
      },
      complete: () => {
        // Call setSideMenuClasses when the observable completes
        setTimeout(() => {
          this.setSideMenuClasses();
        }, 50);
      },
    });
  }
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  setSideMenuClasses() {
    if (document.getElementById('side-menu')) {
      this.menu = new MetisMenu('#side-menu');
      this._activateMenuDropdown();
    }
  }
  hasItems(item: any) {
    return item.submodules !== undefined ? item.submodules.length > 0 : false;
  }

  _removeAllClass(className: any) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }
  slashes = (string: string): number => string.match(/\//g)?.length ?? 0;
  returnUrlwithIndex(url: string): string {
    const slashIndex = url.lastIndexOf('/');
    const slashedUrl = url.substr(0, slashIndex).toString();
    const links: any = document.getElementsByClassName('side-nav-link-ref');
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push('/' + links[i]['href'].replace(links[i]['baseURI'], '').replace('#/', ''));
    }
    const itemIndex = paths.indexOf(slashedUrl);

    if (itemIndex === -1 && slashedUrl.split('/').length > 1) {
      return this.returnUrlwithIndex(slashedUrl);
    } else {
      return slashedUrl;
    }
  }
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links: any = document.getElementsByClassName('side-nav-link-ref');
    if (links.length > 0) {
      let menuItemEl = null;
      const paths = [];
      for (let i = 0; i < links.length; i++) {
        paths.push('/' + links[i]['href'].replace(links[i]['baseURI'], '').replace('#/', ''));
      }
      const itemIndex = paths.indexOf(this._router.url);
      if (itemIndex === -1) {
        menuItemEl = links[paths.indexOf(this.returnUrlwithIndex(this._router.url))];
      } else {
        menuItemEl = links[itemIndex];
      }
      if (menuItemEl) {
        menuItemEl.classList.add('active');
        const parentEl = menuItemEl.parentElement;
        if (parentEl) {
          parentEl.classList.add('mm-active');
          const parent2El = parentEl.parentElement.closest('ul');
          if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show');
            const parent3El = parent2El.parentElement;
            if (parent3El && parent3El.id !== 'side-menu') {
              parent3El.classList.add('mm-active');
              const childAnchor = parent3El.querySelector('.has-arrow');
              const childDropdown = parent3El.querySelector('.has-dropdown');
              if (childAnchor) {
                childAnchor.classList.add('mm-active');
              }
              if (childDropdown) {
                childDropdown.classList.add('mm-active');
              }
              const parent4El = parent3El.parentElement;
              if (parent4El && parent4El.id !== 'side-menu') {
                parent4El.classList.add('mm-show');
                const parent5El = parent4El.parentElement;
                if (parent5El && parent5El.id !== 'side-menu') {
                  parent5El.classList.add('mm-active');
                  const childanchor = parent5El.querySelector('.is-parent');
                  if (childanchor && parent5El.id !== 'side-menu') {
                    childanchor.classList.add('mm-active');
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  logout() {
    this._authService.logout();
  }
}
