import { createAction, props } from '@ngrx/store';
import { permissionModel, permissions } from './permission.model';

export const LOAD_PERMISSION_SUCCESS = 'Success';
export const LOAD_PERMISSION = 'load permission';
export const loadPermissions = createAction(LOAD_PERMISSION);

export const loadpermissionsuccess = createAction(LOAD_PERMISSION_SUCCESS, props<{ permissionList: any }>());

export const createPermission = createAction('createPermission', props<{ permissioninput: permissionModel }>());
