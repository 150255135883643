import { Directive, Input } from '@angular/core';
import { FormGroup, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appMatchPassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MatchPasswordDirective, multi: true }],
  standalone: true,
})
export class MatchPasswordDirective {
  @Input('appMatchPassword') MatchPassword: string[] = [];
  constructor() {}
  validate(formGroup: FormGroup): ValidationErrors {
    return MatchPasswordFn(this.MatchPassword[0], this.MatchPassword[1])(formGroup);
  }
}
function MatchPasswordFn(password: string, confirmPassword: string) {
  return (formGroup: FormGroup): any => {
    const passwordControl = formGroup.get(password);
    const confirmPasswordControl = formGroup.get(confirmPassword);

    if (!passwordControl || !confirmPasswordControl) {
      return {};
    }

    if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
      return {};
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    }
  };
}
