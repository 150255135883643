const imageSieCheck = (imgName: string) => {
  let ratio = {
    height: 0,
    width: 0,
  };

  switch (imgName) {
    case 'bannerImg':
      ratio = {
        height: 800,
        width: 400,
      };
      break;
    case 'bannerImges':
      ratio = {
        height: 200,
        width: 140,
      };
      break;
    case 'backgroundImg': {
      ratio = {
        height: 350,
        width: 400,
      };
      break;
    }
    case 'bookImg': {
      ratio = {
        height: 340,
        width: 340,
      };
      break;
    }
    default:
      break;
  }

  return ratio;
};

export { imageSieCheck };
