import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, PasswordResetData, PermissionsService } from '@shared';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {
  keysToRemoveOfLocalStorage = [environment.tokenKey, environment.permissionKey];
  constructor(
    private http: HttpClient,
    private _router: Router,
  ) {}

  login(email: string, password: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('/v1/auth/login', { email, password });
  }

  logout() {
    for (const key of this.keysToRemoveOfLocalStorage) {
      localStorage.removeItem(key);
    }
    sessionStorage.clear();
    this._router.navigateByUrl('/auth/login');
  }
  forgotPassword(email: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('/v1/auth/forgotpassword', { email });
  }
  resetPassword(resetPasswordInformation: PasswordResetData): Observable<ApiResponse> {
    return this.http.post<ApiResponse>('/v1/auth/reset-password', resetPasswordInformation);
  }
}
