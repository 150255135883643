<dropzone
  #dz
  class="dropzone mb-2"
  [config]="config"
  (success)="handleUploadSuccess($event)"
  (error)="handleonErrorUpload($event)"
  [message]="message"
  (processing)="(processing)"
  name="name"
></dropzone>
