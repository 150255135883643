export enum statusEnum {
  'approve' = 'approved',
  'reject' = 'reject',
}

export enum step_status_enum {
  'departmentHead' = 'DepartmentHead',
  'storeEntryDepartment' = 'StoreEntryDepartment',
  'welcomeOffice' = 'WelcomeOfficeDepartment',
  'finalAuthority' = 'FinalAuthorityDepartment',
  'accountDepartment' = 'AccountDepartment',
}
