import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-action-cell-renderer',
  templateUrl: './action-cell-renderer.component.html',
  styleUrls: ['./action-cell-renderer.component.scss'],
})
export class ActionCellRendererComponent implements ICellRendererAngularComp {
  public displayValue!: any;
  showDeleteButton = true;
  editWithModal = false;
  showEditButton = true;
  showViewButton = false;
  showDownloadButton = false;
  showHistoryButton = false;
  isDisplayEdit = false;
  showStockTransfer = false;
  isBack = false;
  public Data!: any;
  isUpdate = false;
  isDelete = false;
  isRead = false;
  moduleName: any;
  permissionsSubscription: Subscription | undefined;
  stepNumber: number = 0;
  constructor(private permissionsService: NgxPermissionsService) {}
  agInit(params: ICellRendererParams): void {
    this.displayValue = params;
    this.permissionsSubscription = this.permissionsService.permissions$.subscribe((permissions) => {
      if (this.displayValue.moduleName) {
        this.isUpdate = permissions && permissions[`${this.displayValue.moduleName}:update`] ? true : false;
        this.isDelete = permissions && permissions[`${this.displayValue.moduleName}:delete`] ? true : false;
        this.isRead = permissions && permissions[`${this.displayValue.moduleName}:read`] ? true : false;
      }
    });
    this.showDeleteButton = this.displayValue.showDeleteButton ?? this.showDeleteButton;
    this.showEditButton = this.displayValue.showEditButton ?? this.showEditButton;
    this.editWithModal = this.displayValue.editWithModal ?? this.editWithModal;
    this.showViewButton = this.displayValue.showViewButton ?? this.showViewButton;
    this.showHistoryButton = this.displayValue.showHistoryButton ?? this.showHistoryButton;
    this.showStockTransfer = this.displayValue.showStockTransfer ?? this.showStockTransfer;
    this.showDownloadButton = this.displayValue.showDownloadButton ?? this.showDownloadButton;
    if (this.displayValue.isDisplayEdit != null) {
      this.isDisplayEdit = this.displayValue.isDisplayEdit;
      this.displayValue.data.isDisplayDelete = this.isDisplayEdit;
    }
    if (this.displayValue.isBack) {
      this.isUpdate = this.editWithModal = this.isDelete = false;
      this.showDeleteButton = true;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onActionClick(actionType: string, _id: string, data: any) {
    this.Data = data ? data : '';
    this.displayValue.clicked(actionType, _id, this.Data, this.displayValue.data.avatarPath);
  }
  onEditAction(value: any) {
    const stepNumber = value?.data?.stepNumber;
    if (stepNumber) {
      localStorage.setItem('stepNumber', stepNumber);
    }
  }
}
