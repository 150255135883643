/* eslint-disable @typescript-eslint/no-explicit-any */
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { PagesModule } from '../../src/app/pages/pages.module';
import { LayoutsModule } from './layouts/layouts.module';
import { ExtrapagesModule } from './extrapages/extrapages.module';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { API_URL, BaseUrlInterceptor } from './core/interceptors/base-url-interceptor.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { PermissionsService } from '@shared/services/permissions.service';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { StoreModule } from '@ngrx/store';
import { AppState } from '@shared/store/global/app.state';
import { EffectsModule } from '@ngrx/effects';
import { permissionEffects } from '@shared/store/permission/permission.effects';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgToggleModule } from 'ng-toggle-button';
export const loadPermissionsAtReload = (service: PermissionsService) => () => service.loadPermissions();
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function tokenGetter() {
  return JSON.parse(localStorage.getItem(environment.tokenKey) ?? '{}').accessToken;
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    AppRoutingModule,
    // LeafletModule,
    PagesModule,
    LayoutsModule,
    NgbModule,
    HttpClientModule,
    ExtrapagesModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 2,
    }),
    StoreModule.forRoot(AppState),
    EffectsModule.forRoot([permissionEffects]),
    NgxPermissionsModule.forRoot(),
    NgToggleModule.forRoot(),
  ],
  providers: [
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: loadPermissionsAtReload,
      multi: true,
      deps: [PermissionsService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
