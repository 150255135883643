<div class="modal-header">
  <h5 class="modal-title" id="myModalLabel">{{ title | translate }}</h5>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="onClose('cancel')"
  ></button>
</div>
<div class="modal-body">
  <h5 class="font-size-16">{{ message | translate }}</h5>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="onClose('cancel')">
    {{ 'COMMON.CLOSE.TEXT' | translate }}
  </button>
  <button type="button" class="btn btn-info" (click)="onClose('confirm')">
    {{ 'COMMON.CONFIRM.TEXT' | translate }}
  </button>
</div>
