import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SettingService } from '@services';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit {
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = '_id';
  @Input() dropDownFor: string = '';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() maxRecords: number = 2;
  @Input() items: any[] = [];
  @Input() selectedItems: any[] = [];
  @Output() selectedItemsChange = new EventEmitter<any>();
  @Input() name!: string;
  @Input() ngForm!: NgForm;
  @Input() sortOrderField: string = '';
  constructor(public _settingService: SettingService) {}

  ngOnInit(): void {
    if (this.dropDownFor != '') {
      this.getAllItems();
    }
    this.selectAllForDropdownItems(this.items);
  }

  getAllItems() {
    this._settingService
      .getDropDownList({
        pageLimit: 100,
        pageNum: 1,
        dropDownFor: this.dropDownFor,
        sortField: this.sortOrderField,
        sortOrder: 'asc',
      })
      .subscribe({
        next: (response: any) => {
          this.items = response?.data;
          this.selectAllForDropdownItems(this.items);
        },
      });
  }

  selectAllForDropdownItems(items: any[]) {
    const allSelect = (items: any[]) => {
      items.forEach((element) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  onChange() {
    this.selectedItemsChange.emit(this.selectedItems);
  }
}
