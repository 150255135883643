<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box p-0">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img [src]="mainLogoSmall" alt="" class="w-75" />
          </span>
          <span class="logo-lg">
            <img [src]="mainLogo" alt="" class="w-75 pe-4" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img [src]="mainLogoSmall" alt="" class="w-75" />
          </span>
          <span class="logo-lg">
            <img [src]="mainLogo" alt="" class="w-75" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block language-switch" ngbDropdown placement="bottom-right">
        <button
          type="button"
          class="btn header-item"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <img *ngIf="flagvalue !== undefined" src="{{ flagvalue }}" alt="Header Language" height="16" />
          <img *ngIf="flagvalue === undefined" src="{{ valueset }}" alt="Header Language" height="16" />
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{ active: cookieValue === item.lang }"
          >
            <img src="{{ item.flag }}" alt="user-image" class="me-1" height="18" width="25" />
            <span class="align-middle">{{ item.text }}</span>
          </a>
        </div>
      </div>
      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          id="right-bar-toggle"
          (click)="getNotificationListing()"
        >
          <i-feather class="icon-sm" name="bell"></i-feather>
          <span class="notification-count bg-danger" *ngIf="notificationCount !== 0">{{ notificationCount }}</span>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <span class="ms-2 d-none d-sm-block user-item-desc">
            <span class="user-name">{{ _currentUser.firstName }} {{ _currentUser.lastName }}</span>
            <span class="user-sub-title"> {{ _currentUser.rolename | titlecase }} </span>
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-bottom dropdown-menu-end pt-0" ngbDropdownMenu>
          <div class="p-3 btn-info">
            <h6 class="mb-0 text-white">{{ _currentUser.firstName }} {{ _currentUser.lastName }}</h6>
            <p class="mb-0 font-size-11 text-white-50 fw-semibold">{{ _currentUser.email }}</p>
          </div>
          <!-- <a class="dropdown-item" routerLink=
            ><i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Profile</span></a
          >
          <a class="dropdown-item" routerLink="/chat"
            ><i class="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Messages</span></a
          >
          <a class="dropdown-item" routerLink="/kanban-board"
            ><i class="mdi mdi-calendar-check-outline text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Taskboard</span></a
          >
          <a class="dropdown-item" routerLink="/pages/faqs"
            ><i class="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Help</span></a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/pages/profile"
            ><i class="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Balance : <b>$6951.02</b></span></a
          >
          <a class="dropdown-item d-flex align-items-center" routerLink="/contacts/settings"
            ><i class="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Settings</span><span class="badge badge-soft-success ms-auto">New</span></a
          >
          <a class="dropdown-item" routerLink="/account/lockscreen/basic"
            ><i class="mdi mdi-lock text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Lock screen</span></a
          > -->
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"
            ><i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Logout</span></a
          >
        </div>
      </div>
    </div>
  </div>
</header>
