<div class="modal-header">
  <h5 class="modal-title text-capitalize" id="myModalLabel">
    {{ headerText ? headerText : ('IMPORTTITLE.TEXT' | translate) }}
  </h5>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="onClose('cancel')"
  ></button>
</div>
<div class="row">
  <div class="col-12">
    <div class="card mb-0">
      <!-- end card header -->
      <div class="card-body">
        <div>
          <label class="form-label required d-flex justify-content-center">{{ 'IMPORTLABEL.TEXT' | translate }}</label>
          <dropzone
            #dz
            class="dropzoneforimport"
            (error)="onUploadError($event)"
            (success)="onUploadFile($event)"
            [config]="config"
            [message]="message"
          ></dropzone>
        </div>

        <div class="text-center mt-4">
          <button type="button" (click)="SampleFile()" class="btn btn-info col-md-3 float-start me-2">
            {{ 'SAMPLEFILE.TEXT' | translate }}
          </button>
          <button type="button" class="btn btn-success float-end col-md-3" (click)="importFile()">
            {{ 'DATATABLE.BUTTONS.IMPORT' | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger float-end col-md-3 me-2"
            (click)="RemoveFile()"
            *ngIf="removeFile === true"
          >
            {{ 'REMOVEFILE.TEXT' | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
