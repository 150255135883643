import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@services';
import { BreadCrumbHeader } from 'app/core/models/bread-crumb.model';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent {
  @Output() backClick = new EventEmitter<null>();
  @Input() breadCrumbHeader: BreadCrumbHeader = new BreadCrumbHeader();

  constructor(
    public route: ActivatedRoute,
    public _router: Router,
    public _language: LanguageService,
  ) {}
  backClicked() {
    this.backClick.emit();
  }
}
