import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layouts/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthlayoutComponent } from './layouts/authlayout/authlayout.component';
import { LoginGuard } from './core/guards/login.guard';
import { Basic404Component } from './extrapages/basic404/basic404.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pages',
    loadChildren: () => import('./extrapages/extrapages.module').then((m) => m.ExtrapagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    component: AuthlayoutComponent,
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    canActivate: [LoginGuard],
  },
  { path: '**', component: Basic404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
