<div class="row">
  <div
    class="col-md-1 backButton"
    *ngIf="_router.url.includes('edit') || _router.url.includes('add') || route.snapshot.paramMap.get('id')"
  >
    <button (click)="backClicked()" class="btn btn-soft-primary">
      <i-feather name="arrow-left" *ngIf="_language.getCurrentLang() === 'en'" class="d-flex"></i-feather>
      <i-feather name="arrow-right" *ngIf="_language.getCurrentLang() === 'he'" class="d-flex"></i-feather>
    </button>
  </div>
  <div
    class="col-md-12"
    [ngClass]="
      _router.url.includes('edit') || _router.url.includes('add') || route.snapshot.paramMap.get('id')
        ? 'breadcrumbParent'
        : 'breadcrumbGrid'
    "
  >
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0" [class]="_router.url.includes('edit') || _router.url.includes('add') ? 'breadcrumb-title' : ''">
        {{ breadCrumbHeader.title | translate }}
      </h4>

      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadCrumbHeader?.breadCrumb">
            <li class="breadcrumb-item" *ngIf="item?.routeUrl; else currentBreadCrumb">
              <a
                [routerLink]="item?.routeUrl"
                [ngClass]="{ active: item.isActive, 'no-pointer-cursor': !item.isActive }"
                >{{ item.name | translate }}</a
              >
            </li>
            <ng-template #currentBreadCrumb>
              <li class="breadcrumb-item">
                <label>{{ item.name | translate }}</label>
              </li>
            </ng-template>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>
