import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ReactiveFormsModule } from '@angular/forms';

import { PagesRoutingModule } from './pages-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../shared/widget/widget.module';
import { ExtrapagesModule as ThemeExtraPagesModule } from '../extrapages/extrapages.module';
import { TranslateModule } from '@ngx-translate/core';
import { allIcons } from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';
import { CustomvalidationService } from '../core/services/validation/customValidation.service';
import { NgxPermissionsModule } from 'ngx-permissions';
@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgbDropdownModule,
    NgApexchartsModule,
    SharedModule,
    WidgetModule,
    // LeafletModule,
    SimplebarAngularModule,
    ReactiveFormsModule,
    NgbNavModule,
    NgbTooltipModule,
    ThemeExtraPagesModule,
    TranslateModule,
    FeatherModule.pick(allIcons),
    NgxPermissionsModule.forChild(),
  ],
  providers: [DatePipe, CustomvalidationService],
})
export class PagesModule {}
