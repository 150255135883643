import { Component, Input } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-form-error-tip',
  templateUrl: './from-error.component.html',
  styleUrls: ['./from-error.component.scss'],
})
export class FromErrorComponent {
  @Input() fieldModel!: NgModel;
  @Input() name!: string;
  @Input() ngForm!: NgForm;
}
