import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from 'app/core/services/language.service';
import { AuthenticationService } from 'app/core/services/auth.service';
import { TokenService } from 'app/core/services/token.service';
import { TokenUser } from '@shared';
import { environment } from '@env/environment';
import { TopbarService } from 'app/core/services/topbar.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  mode: string | undefined;
  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;
  _User!: TokenUser;
  _currentUser!: any;
  notificationCount: number = 0;
  notificationMessage: any;
  mainLogo = 'assets/images/logo/main_logo.png';
  mainLogoSmall = 'assets/images/finalLogo.png';
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    public _languageService: LanguageService,
    public _cookiesService: CookieService,
    private _authService: AuthenticationService,
    private _tokenService: TokenService,
    public notificationService: TopbarService,
    private _toast: ToastrService,
    public translate: TranslateService,
  ) {}

  listLang = [
    { text: 'English', flag: 'assets/images/flags/india_flag.jpg', lang: 'en' },
    // { text: 'Hebrew', flag: 'assets/images/flags/israel.jpg', lang: 'he' },
  ];

  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {
    this.getLanguageFromCookies();
    this._currentUser = this._tokenService.getCurrentUser();
    this.getNotificationCount();
  }

  getLanguageFromCookies() {
    this.cookieValue = this._cookiesService.get(environment.languageCookieKey);
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this._languageService.setLanguage(lang);
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
    this.getNotificationCount();
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this._authService.logout();
  }

  getNotificationCount() {
    this.notificationService.getNotificationCount().subscribe({
      next: (response: any) => {
        this.notificationCount = response.data;
      },
      error: (error) => {
        this._toast.error(error || this.translate.instant('WENTWRONG.TEXT'), 'Error');
      },
    });
  }

  getNotificationListing() {
    this.settingsButtonClicked.emit();
    this.getNotificationCount();
  }
}
