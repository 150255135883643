import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse } from '@shared/interfaces/apiResponse.interface';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Injectable({
  providedIn: 'root',
})
export class UploadS3Service {
  environment = environment;
  TEMP_ASSETS = [
    'profileImg',
    'ePubFile',
    'audioSummary',
    'bookImg',
    'bannerImg',
    'backgroundImg',
    'stripIcon',
    'areaIcon',
    'audioFile',
    'notificationImage',
    'dedicationImg',
    'dedicationAudio',
  ];
  constructor(private http: HttpClient) {}
  generateDynamicUploadPath(subfolder: string, filename: string): string {
    const key = this.TEMP_ASSETS.includes(subfolder)
      ? `${environment.awsenv}/assets/public/temp`
      : `${environment.awsenv}/assets/public`;

    return key + '/' + subfolder + '/' + filename;
  }
  uploadFile(data: any): Promise<any> {
    return this.http
      .post('/v1/admin/homePage/getSignedURLs', data)
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}
