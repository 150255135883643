<div class="row">
  <div class="col text-capitalize">
    <h5
      class="m-0 badge py-2 statusFont"
      [ngClass]="{
        'badge-soft-warning':
          _value === 'Pending' ||
          _value === 'In Progress' ||
          _value === 'Reset Request' ||
          _value === 'pending' ||
          _value === 'Warranty Entry of Products' ||
          _value === 'unpaid',
        'badge-soft-success':
          _value === 'approved' ||
          _value === 'COMMON.YES.TEXT' ||
          _value === true ||
          _value === 'Active' ||
          _value === 'New' ||
          _value === 'active' ||
          _value === 'Yes' ||
          _value === 'done' ||
          _value === 'true' ||
          _value === 'Send Confirmation' ||
          _value === 'completed' ||
          _value === 'Paid',
        'badge-soft-danger':
          _value === 'deny' ||
          _value === 'COMMON.NO.TEXT' ||
          _value === false ||
          _value === 'false' ||
          _value === 'Not Active' ||
          _value === 'Canceled' ||
          _value === 'Rejected' ||
          _value === 'Closed' ||
          _value === 'inactive' ||
          _value === 'No' ||
          _value === 'Get Quotation' ||
          _value === 'Deleted',
        'badge-soft-secondary': _value === 'process' || _value === 'Generate Po',
        'badge-soft-primary': _value === 'Send Quotation',
        'badge-soft-info': _value === 'Product Verification',
        'badge-soft-light': _value === 'Move Product to Department'
      }"
    >
      {{ _value | translate }}
    </h5>
  </div>
</div>
