import { Injectable } from '@angular/core';
import { IUploadFields } from '../interfaces/IClient';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class FileuploadService {
  constructor(private http: HttpClient) {}
  uploadFile(uploadFIle: IUploadFields) {
    return this.http.post('/v1/files/upload', uploadFIle);
  }
  removeFile(path: string) {
    return this.http.post<ApiResponse>('/v1/files/remove', { path });
  }
}
