<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/finalLogo.png" alt="" height="40" class="w-100" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo/main_logo.png" alt="" height="40" class="w-100" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="22" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
        data-bs-toggle="collapse"
        data-bs-target="#topnav-menu-content"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <div class="dropdown d-none d-lg-block ms-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          data-bs-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <span>{{ 'TOPBAR.COMPONENT.TEXT' | translate }}</span>
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-xl p-2" ngbDropdownMenu>
          <div class="row">
            <div class="col-md-6">
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.COMPONENT.LIST.ALERTS' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.COMPONENT.LIST.BUTTONS' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.COMPONENT.LIST.CARDS' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.COMPONENT.LIST.DROPDOWNS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.COMPONENT.LIST.LIGHTBOX' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.COMPONENT.LIST.MODALS' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.COMPONENT.LIST.RANGESLIDER' | translate
              }}</a>
            </div>
            <div class="col-md-6">
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.COMPONENT.LIST.PROGRESSBARS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.COMPONENT.LIST.SWEETALERTS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.COMPONENT.LIST.TABS&ACCODIONS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.COMPONENT.LIST.TYPOGRAPHY' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.COMPONENT.LIST.GENERAL' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.COMPONENT.LIST.RATING' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.COMPONENT.LIST.NOTIFICATIONS' | translate
              }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown dropdown-mega d-none d-lg-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          data-bs-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <span>{{ 'TOPBAR.CATEGORIES.TEXT' | translate }}</span>
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu p-2 dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col">
              <h5 class="font-size-14 mx-4 mt-2">{{ 'TOPBAR.CATEGORIES.LIST.COMPUTERSACCESSORIES' | translate }}</h5>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.LAPTOPSTABLETS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.DESKTOPCOMPUTERS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.NETWORKINGPRODUCTS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.MONITORS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.BAGS' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.BATTERIES' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.CHARGES' | translate }}</a>
            </div>
            <div class="col">
              <h5 class="font-size-14 ms-4 mt-2">{{ 'TOPBAR.CATEGORIES.LIST.SMARTPHONES' | translate }}</h5>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.APPLEIPHONE' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.ANDROID' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.PHABLETS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.APPLEIPAD' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.ANDROIDTABLETS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.TABLETS' | translate }}</a>
            </div>
            <div class="col">
              <h5 class="font-size-14 ms-4 mt-2">{{ 'TOPBAR.CATEGORIES.LIST.TELEVISION' | translate }}</h5>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.TV' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.HOME' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.DVDPLAYERS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.DVDVCR' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.PROJECTORS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.PROJECTIONSCREENS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.SATELITE' | translate
              }}</a>
            </div>
            <div class="col">
              <h5 class="font-size-14 ms-4 mt-2">{{ 'TOPBAR.CATEGORIES.LIST.CAMERAS' | translate }}</h5>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.POINT' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.DSLRCAMERAS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.MIRRORLESS' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{ 'TOPBAR.CATEGORIES.LIST.BODY' | translate }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.CAMERALENSES' | translate
              }}</a>
              <a class="dropdown-item" href="javascript:void(0);">{{
                'TOPBAR.CATEGORIES.LIST.VIDEOSTUDIO' | translate
              }}</a>
            </div>
            <div class="col">
              <div class="py-lg-2 pr-lg-2">
                <img src="assets/images/illustrator/1.png" alt="" class="img-fluid mx-auto" style="max-height: 250px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <i-feather class="icon-sm" name="search"></i-feather>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>
          <form class="p-2">
            <div class="search-box">
              <div class="position-relative">
                <input type="text" class="form-control rounded bg-light border-0" placeholder="Search..." />
                <i class="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="dropdown d-inline-block language-switch" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <img *ngIf="flagvalue !== undefined" src="{{ flagvalue }}" alt="Header Language" height="16" />
          <img *ngIf="flagvalue === undefined" src="{{ valueset }}" alt="Header Language" height="16" />
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{ active: cookieValue === item.lang }"
          >
            <img src="{{ item.flag }}" alt="user-image" class="me-1" height="12" />
            <span class="align-middle">{{ item.text }}</span>
          </a>
        </div>
      </div>
      <div class="dropdown d-none d-lg-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <i-feather class="icon-sm" name="grid"></i-feather>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>
          <div class="p-3 border-bottom">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-15">Web Apps</h5>
              </div>
              <div class="col-auto">
                <a href="javascript:void(0);" class="small fw-semibold text-decoration-underline"> View All</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="max-height: 270px">
            <a class="notification-item text-reset" href="javascript:void(0);">
              <div class="d-flex border-bottom align-items-center">
                <div class="flex-shrink-0">
                  <img src="assets/images/brands/slack.png" alt="slack" />
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6 class="mb-0">Slack</h6>
                  <p class="font-size-12 text-muted mb-0">This is your moment. Let’s reinvent work.</p>
                </div>
              </div> </a
            ><!-- end dropdown item -->
            <a class="notification-item text-reset" href="javascript:void(0);">
              <div class="d-flex border-bottom align-items-center">
                <div class="flex-shrink-0">
                  <img src="assets/images/brands/behance.png" alt="behance" />
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6 class="mb-0">Behance <span class="badge bg-warning badge-pill ml-1">Free</span></h6>
                  <p class="font-size-12 text-muted mb-0">Showcase your work.</p>
                </div>
              </div> </a
            ><!-- end dropdown item -->
            <a class="notification-item text-reset" href="javascript:void(0);">
              <div class="d-flex border-bottom align-items-center">
                <div class="flex-shrink-0">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble" />
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6 class="mb-0">Dribbble</h6>
                  <p class="font-size-12 text-muted mb-0">
                    Dribbble is the world’s leading community for creatives to share, grow, and get hired.
                  </p>
                </div>
              </div> </a
            ><!-- end dropdown item -->
            <a class="notification-item text-reset" href="javascript:void(0);">
              <div class="d-flex border-bottom align-items-center">
                <div class="flex-shrink-0">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox" />
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6 class="mb-0">Dropbox</h6>
                  <p class="font-size-12 text-muted mb-0">Keep life organized and work moving—all in one place</p>
                </div>
              </div> </a
            ><!-- end dropdown item -->
            <a class="notification-item text-reset" href="javascript:void(0);">
              <div class="d-flex border-bottom align-items-center">
                <div class="flex-shrink-0">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp" />
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6 class="mb-0">Mail Chimp <span class="badge bg-danger badge-pill ml-1">Premium</span></h6>
                  <p class="font-size-12 text-muted mb-0">The best value for your digital marketing budget</p>
                </div>
              </div> </a
            ><!-- end dropdown item -->
            <a class="notification-item text-reset" href="javascript:void(0);">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img src="assets/images/brands/github.png" alt="github" />
                </div>
                <div class="flex-grow-1 ms-4">
                  <h6 class="mb-0">Github</h6>
                  <p class="font-size-12 text-muted mb-0">Where the world builds software</p>
                </div>
              </div> </a
            ><!-- end dropdown item --> </ngx-simplebar
          ><!-- end simplebar -->
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <i-feather class="icon-sm" name="bell"></i-feather>
          <span class="noti-dot bg-danger"></span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          ngbDropdownMenu
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-15">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate }}</h5>
              </div>
              <div class="col-auto">
                <a href="javascript:void(0);" class="small"> Mark all as read</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="max-height: 250px">
            <h6 class="dropdown-header bg-light">{{ 'HEADER.NOTIFICATIONS.NEW' | translate }}</h6>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-sm" alt="user-pic" />
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate }}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate }}</p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold">
                      <i class="mdi mdi-clock-outline"></i> {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <div class="avatar-sm me-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class="uil-shopping-basket"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate }}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate }}</p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold">
                      <i class="mdi mdi-clock-outline"></i> {{ 'HEADER.NOTIFICATIONS.SECOND.TIME' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <h6 class="dropdown-header bg-light">{{ 'HEADER.NOTIFICATIONS.EARLIER' | translate }}</h6>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <div class="avatar-sm me-3">
                    <span class="avatar-title bg-soft-success text-success rounded-circle font-size-16">
                      <i class="uil-truck"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate }}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate }}</p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold">
                      <i class="mdi mdi-clock-outline"></i> {{ 'HEADER.NOTIFICATIONS.THIRD.TIME' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-sm" alt="user-pic" />
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate }}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate }}</p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold">
                      <i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
              <i class="uil-arrow-circle-right me-1"></i> <span>View More..</span>
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          id="right-bar-toggle"
          (click)="toggleRightSidebar()"
        >
          <i-feather class="icon-sm" name="settings"></i-feather>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <img class="rounded-circle header-profile-user" alt="Header Avatar" />
          <span class="ms-2 d-none d-sm-block user-item-desc">
            <span class="user-name">Kate Dudley</span>
            <span class="user-sub-title">Administrator</span>
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-end pt-0" ngbDropdownMenu>
          <div class="p-3 bg-primary border-bottom">
            <h6 class="mb-0 text-white">Kate Dudley</h6>
            <!-- <p class="mb-0 font-size-11 text-white-50 fw-semibold">kate.dudley@email.com</p> -->
          </div>
          <a class="dropdown-item" routerLink="/pages/profile"
            ><i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Profile</span></a
          >
          <a class="dropdown-item" routerLink="/chat"
            ><i class="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Messages</span></a
          >
          <a class="dropdown-item" routerLink="/kanban-board"
            ><i class="mdi mdi-calendar-check-outline text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Taskboard</span></a
          >
          <a class="dropdown-item" routerLink="/pages/faqs"
            ><i class="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Help</span></a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/pages/profile"
            ><i class="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Balance : <b>$6951.02</b></span></a
          >
          <a class="dropdown-item d-flex align-items-center" routerLink="/contacts/settings"
            ><i class="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Settings</span><span class="badge badge-soft-success ms-auto">New</span></a
          >
          <a class="dropdown-item" routerLink="/account/lockscreen/basic"
            ><i class="mdi mdi-lock text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Lock screen</span></a
          >
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"
            ><i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Logout</span></a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of menuItems">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle arrow-none"
                  *ngIf="!item.subItems"
                  (click)="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  [routerLink]="item.link"
                  role="button"
                >
                  <i-feather name="{{ item.icon }}"></i-feather>{{ item.label | translate }}
                  <div class="arrow-down" *ngIf="hasItems(item)"></div>
                </a>
                <a
                  *ngIf="item.subItems"
                  class="nav-link dropdown-toggle arrow-none"
                  (click)="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  role="button"
                >
                  <i-feather name="{{ item.icon }}"></i-feather>
                  {{ item.label | translate }}
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu"
                  [ngClass]="{ 'dropdown-mega-menu-xl': item.subItems.length > 11 }"
                  aria-labelledby="topnav-dashboard"
                  *ngIf="hasItems(item)"
                >
                  <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                    <a
                      *ngIf="item.subItems.length < 11 && !hasItems(subitem)"
                      class="col dropdown-item side-nav-link-ref"
                      [routerLink]="subitem.link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                    >
                      {{ subitem.label | translate }}
                    </a>
                    <div *ngIf="item.subItems.length > 11">
                      <div *ngIf="i % 3 === 0" class="row">
                        <div class="col-lg-4">
                          <a
                            class="dropdown-item side-nav-link-ref"
                            routerLinkActive="active"
                            [routerLink]="subitem.link"
                            >{{ item.subItems[i].label | translate }}</a
                          >
                        </div>
                        <div class="col-lg-4">
                          <a
                            class="dropdown-item side-nav-link-ref"
                            routerLinkActive="active"
                            [routerLink]="item.subItems[i + 1]?.link"
                            >{{ item.subItems[i + 1]?.label | translate }}</a
                          >
                        </div>
                        <div class="col-lg-4">
                          <a
                            class="dropdown-item side-nav-link-ref"
                            routerLinkActive="active"
                            [routerLink]="item.subItems[i + 2]?.link"
                            >{{ item.subItems[i + 2]?.label | translate }}</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="dropdown" *ngIf="hasItems(subitem)">
                      <a class="dropdown-item dropdown-toggle" href="javascript: void(0);" (click)="onMenuClick($event)"
                        >{{ subitem.label | translate }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                          <a
                            class="dropdown-item side-nav-link-ref"
                            *ngIf="!hasItems(subSubitem)"
                            href="javascript: void(0);"
                            [routerLink]="subSubitem.link"
                            routerLinkActive="active"
                            >{{ subSubitem.label | translate }}</a
                          >
                          <div class="dropdown" *ngIf="hasItems(subSubitem)">
                            <a
                              class="dropdown-item dropdown-toggle"
                              href="javascript: void(0);"
                              (click)="onMenuClick($event)"
                              >{{ subSubitem.label | translate }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                                <a
                                  class="dropdown-item side-nav-link-ref"
                                  href="javascript: void(0);"
                                  [routerLink]="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ subSubSubitem.label | translate }}</a
                                >
                              </ng-template>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
