import { MenuItem } from '@shared';

export const MENU: MenuItem[] = [
  {
    _id: 1,
    moduleName: 'MENUITEMS.SETTINGS.TEXT',
  },
  {
    _id: 2,
    moduleName: 'MENUITEMS.SALES.TEXT',
    icon: 'monitor',
    link: '/',
    badge: {
      variant: 'badge-soft-secondary',
      text: 'MENUITEMS.SALES.BADGE',
    },
  },
  {
    _id: 4,
    moduleName: 'MENUITEMS.APPLICATIONS.TEXT',
    isTitle: true,
  },
  {
    _id: 5,
    moduleName: 'MENUITEMS.CHAT.TEXT',
    icon: 'message-square',
    link: '/chat',
    badge: {
      variant: 'badge-soft-danger',
      text: 'MENUITEMS.CHAT.BADGE',
    },
  },
  {
    _id: 6,
    moduleName: 'MENUITEMS.CLIENTS.TEXT',
    icon: 'users',
    link: '/clients',
  },
  {
    _id: 21,
    moduleName: 'MENUITEMS.PAGES.TEXT',
    isTitle: true,
  },
  {
    _id: 22,
    moduleName: 'MENUITEMS.AUTHENTICATION.TEXT',
    icon: 'user',
    badge: {
      variant: 'info',
      text: 'MENUITEMS.AUTHENTICATION.BADGE',
    },
    submodules: [
      {
        _id: 23,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.SIGNIN',
        submodules: [
          {
            _id: 24,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/signin/basic',
            parent_id: 23,
          },
          {
            _id: 25,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/signin/cover',
            parent_id: 23,
          },
        ],
      },
      {
        _id: 26,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.SIGNUP',
        submodules: [
          {
            _id: 27,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/signup/basic',
            parent_id: 26,
          },
          {
            _id: 28,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/signup/cover',
            parent_id: 26,
          },
        ],
      },
      {
        _id: 29,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.SIGNOUT',
        submodules: [
          {
            _id: 30,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/signout/basic',
            parent_id: 29,
          },
          {
            _id: 31,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/signout/cover',
            parent_id: 29,
          },
        ],
      },
      {
        _id: 32,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
        submodules: [
          {
            _id: 33,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/lockscreen/basic',
            parent_id: 32,
          },
          {
            _id: 34,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/lockscreen/cover',
            parent_id: 32,
          },
        ],
      },
      {
        _id: 35,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.FORGOTPASSWORD',
        submodules: [
          {
            _id: 36,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/forgot-password/basic',
            parent_id: 32,
          },
          {
            _id: 37,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/forgot-password/cover',
            parent_id: 32,
          },
        ],
      },
      {
        _id: 38,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.RESETPWD',
        submodules: [
          {
            _id: 39,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/reset-password/basic',
            parent_id: 38,
          },
          {
            _id: 40,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/reset-password/cover',
            parent_id: 38,
          },
        ],
      },
      {
        _id: 41,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
        submodules: [
          {
            _id: 42,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/email-verification/basic',
            parent_id: 41,
          },
          {
            _id: 43,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/email-verification/cover',
            parent_id: 41,
          },
        ],
      },
      {
        _id: 44,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
        submodules: [
          {
            _id: 45,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/twostep-verification/basic',
            parent_id: 44,
          },
          {
            _id: 46,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/twostep-verification/cover',
            parent_id: 44,
          },
        ],
      },
      {
        _id: 47,
        moduleName: 'MENUITEMS.AUTHENTICATION.LIST.THANKYOU',
        submodules: [
          {
            _id: 48,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.BASIC',
            link: 'account/thankyou/basic',
            parent_id: 47,
          },
          {
            _id: 49,
            moduleName: 'MENUITEMS.AUTHENTICATION.LIST.COVER',
            link: 'account/thankyou/cover',
            parent_id: 47,
          },
        ],
      },
    ],
  },
  {
    _id: 50,
    moduleName: 'MENUITEMS.ERRORSPAGES.TEXT',
    icon: 'alert-circle',
    submodules: [
      {
        _id: 51,
        moduleName: 'MENUITEMS.ERRORSPAGES.LIST.404BASIC',
        link: '/pages/404-basic',
        parent_id: 50,
      },
      {
        _id: 52,
        moduleName: 'MENUITEMS.ERRORSPAGES.LIST.404COVER',
        link: '/pages/404-cover',
        parent_id: 50,
      },
      {
        _id: 53,
        moduleName: 'MENUITEMS.ERRORSPAGES.LIST.500BASIC',
        link: '/pages/500-basic',
        parent_id: 50,
      },
      {
        _id: 53,
        moduleName: 'MENUITEMS.ERRORSPAGES.LIST.500COVER',
        link: '/pages/500-cover',
        parent_id: 50,
      },
    ],
  },
  {
    _id: 54,
    moduleName: 'MENUITEMS.UTILITY.TEXT',
    icon: 'file-text',
    submodules: [
      {
        _id: 55,
        moduleName: 'MENUITEMS.UTILITY.LIST.STARTER',
        link: '/pages/starter',
        parent_id: 54,
      },
      {
        _id: 56,
        moduleName: 'MENUITEMS.UTILITY.LIST.PROFILE',
        link: '/pages/profile',
        parent_id: 54,
      },
      {
        _id: 56,
        moduleName: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
        link: '/pages/maintenance',
        parent_id: 54,
      },
      {
        _id: 57,
        moduleName: 'MENUITEMS.UTILITY.LIST.COMINGSOON',
        link: '/pages/comingsoon',
        parent_id: 54,
      },
      {
        _id: 58,
        moduleName: 'MENUITEMS.UTILITY.LIST.FAQS',
        link: '/pages/faqs',
        parent_id: 54,
      },
    ],
  },
  {
    _id: 62,
    moduleName: 'MENUITEMS.INVOICES.TEXT',
    icon: 'file',
    submodules: [
      {
        _id: 63,
        moduleName: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
        link: '/invoices/list',
        parent_id: 62,
      },
      {
        _id: 64,
        moduleName: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
        link: '/invoices/detail',
        parent_id: 62,
      },
    ],
  },
  {
    _id: 65,
    moduleName: 'MENUITEMS.COMPONENTS.TEXT',
    isTitle: true,
  },
  {
    _id: 66,
    moduleName: 'MENUITEMS.UIELEMENTS.TEXT',
    icon: 'package',
    submodules: [
      {
        _id: 67,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.ALERTS',
        link: '/ui/alerts',
        parent_id: 66,
      },
      {
        _id: 67,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.BUTTONS',
        link: '/ui/buttons',
        parent_id: 66,
      },
      {
        _id: 67,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.CARDS',
        link: '/ui/cards',
        parent_id: 66,
      },
      {
        _id: 68,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.CAROUSEL',
        link: '/ui/carousel',
        parent_id: 66,
      },
      {
        _id: 69,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.DROPDOWNS',
        link: '/ui/dropdowns',
        parent_id: 66,
      },
      {
        _id: 70,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.GR_id',
        link: '/ui/gr_id',
        parent_id: 66,
      },
      {
        _id: 71,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.IMAGES',
        link: '/ui/images',
        parent_id: 66,
      },
      {
        _id: 72,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.MODALS',
        link: '/ui/modals',
        parent_id: 66,
      },
      {
        _id: 73,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.PROGRESSBAR',
        link: '/ui/progressbars',
        parent_id: 66,
      },
      {
        _id: 74,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.TABS',
        link: '/ui/tabs',
        parent_id: 66,
      },
      {
        _id: 75,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.TYPOGRAPHY',
        link: '/ui/typography',
        parent_id: 66,
      },
      {
        _id: 76,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.V_idEO',
        link: '/ui/v_ideo',
        parent_id: 66,
      },
      {
        _id: 77,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.GENERAL',
        link: '/ui/general',
        parent_id: 66,
      },
      {
        _id: 78,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.COLORS',
        link: '/ui/colors',
        parent_id: 66,
      },
      {
        _id: 79,
        moduleName: 'MENUITEMS.UIELEMENTS.LIST.UTILITIES',
        link: '/ui/utilities',
        parent_id: 66,
      },
    ],
  },
  {
    _id: 87,
    moduleName: 'MENUITEMS.W_idGETS.TEXT',
    icon: 'gr_id',
    link: '/w_idgets',
  },
  {
    _id: 88,
    moduleName: 'MENUITEMS.FORMS.TEXT',
    icon: 'edit-3',
    submodules: [
      {
        _id: 89,
        moduleName: 'MENUITEMS.FORMS.LIST.ELEMENTS',
        link: '/forms/basic',
        parent_id: 88,
      },
      {
        _id: 89,
        moduleName: 'MENUITEMS.FORMS.LIST.VAL_idATION',
        link: '/forms/val_idation',
        parent_id: 88,
      },
      {
        _id: 90,
        moduleName: 'MENUITEMS.FORMS.LIST.ADVANCED',
        link: '/forms/advanced',
        parent_id: 88,
      },
      {
        _id: 91,
        moduleName: 'MENUITEMS.FORMS.LIST.EDITOR',
        link: '/forms/editors',
        parent_id: 88,
      },
      {
        _id: 92,
        moduleName: 'MENUITEMS.FORMS.LIST.FILEUPLOAD',
        link: '/forms/uploads',
        parent_id: 88,
      },
      {
        _id: 93,
        moduleName: 'MENUITEMS.FORMS.LIST.WIZARD',
        link: '/forms/wizard',
        parent_id: 88,
      },
      {
        _id: 94,
        moduleName: 'MENUITEMS.FORMS.LIST.MASK',
        link: '/forms/mask',
        parent_id: 88,
      },
    ],
  },
  {
    _id: 124,
    moduleName: 'MENUITEMS.MULTILEVEL.TEXT',
    icon: 'share-2',
    submodules: [
      {
        _id: 125,
        moduleName: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.1',
        parent_id: 124,
      },
      {
        _id: 126,
        moduleName: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.2',
        submodules: [
          {
            _id: 127,
            moduleName: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1',
            parent_id: 126,
          },
          {
            _id: 128,
            moduleName: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2',
            parent_id: 126,
          },
        ],
      },
    ],
  },

  {
    _id: 125,
    moduleName: 'MENUITEMS.ADMIN.TEXT',
    icon: 'user',
    link: '/settings/admin-type/add',
  },
];
